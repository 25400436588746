import { Button, Col, FloatingLabel, Form, Row, Stack } from 'react-bootstrap';
import { PaymentProps } from '../../types/payments';
import Select from 'react-select';
import { paymentMode } from '../../pages/bd/order/order-form';

function PaymentsHeader({ onChangeFilterText, onFilterApply, handleResetFilters, filters }: PaymentProps) {

    const selectedStatus = { label: filters.status, value: filters.status };
    const selectedPaymentType = { label: filters.paymentType, value: filters.paymentType };
    const selectedVerification = { label: filters.isVerified ? 'Verified' : 'Non verified', value: filters.isVerified };
    const paymentTypeOptions = Object.keys(paymentMode).map((key) => {
        return { label: key, value: key }
    });
    paymentTypeOptions.push({ label: 'NA', value: 'NA' });

    return (
        <div style={{ width: '90%' }}>
            <Row className="align-items-center mt-3 mb-3">
                <Col className="mb-2" xs={12} sm={6} md={4} lg={3}>
                    {/* <FloatingLabel
                        controlId="floatingInput"
                        label="Search by Student name or number"
                        className="mb-3"
                    > */}
                        <Form.Label>Search by Student name or number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Student name or number"
                            onChange={(e) => onChangeFilterText(e.target.value, 'search')}
                            value={filters.search}
                        />
                    {/* </FloatingLabel> */}
                </Col>

                <Col xs={12} sm={6} md={4} lg={3} className="mb-2">
                    {/* <FloatingLabel
                        controlId="From"
                        label="From"
                        className="mb-3"
                    > */}
                        <Form.Label>From</Form.Label>
                        <Form.Control
                            type="date"
                            onChange={(e) => onChangeFilterText(e.target.value, 'from')}
                            value={filters.from}
                        />
                    {/* </FloatingLabel> */}
                </Col>

                <Col xs={12} sm={6} md={4} lg={3} className="mb-2">
                    {/* <FloatingLabel
                        controlId="To"
                        label="To"
                        className="mb-3"
                    > */}
                        <Form.Label>To</Form.Label>
                        <Form.Control
                            type="date"
                            onChange={(e) => onChangeFilterText(e.target.value, 'to')}
                            value={filters.to}
                        />
                    {/* </FloatingLabel> */}
                </Col>

                <Col xs={12} sm={6} md={4} lg={3} className="mb-2">
                   <Form.Label>Status</Form.Label>
                    <Select
                        options={[
                            { label: "Completed", value: "Complete" },
                            { label: "Pending", value: "Pending" },
                            // { label: "NPA", value: "NPA" }
                        ]}
                        onChange={(e) => onChangeFilterText(e?.value, 'status')}
                        value={selectedStatus}
                    />
                </Col>

                <Col xs={12} sm={6} md={4} lg={3}>
                  <Form.Label>Payment Type</Form.Label>
                    <Select
                        options={paymentTypeOptions}
                        onChange={(e) => onChangeFilterText(e?.value, 'paymentType')}
                        value={selectedPaymentType}
                        placeholder='Payment Type'
                    />
                </Col>

                <Col xs={12} sm={6} md={4} lg={3}>
                  <Form.Label>Verification</Form.Label>
                    <Select
                        options={[
                            { label: "Verified", value: true },
                            { label: "Non verified", value: false },
                        ]}
                        value={selectedVerification}
                        onChange={(e) => onChangeFilterText(e?.value, 'isVerified')}
                    />
                </Col>
            </Row>

            <Stack direction="horizontal" gap={3} className="mt-3">
                <Button variant="primary" onClick={onFilterApply}>
                    Filter
                </Button>
                <Button variant="outline-secondary" onClick={handleResetFilters}>
                    Reset
                </Button>
            </Stack>
        </div>
    );
}

export default PaymentsHeader;
