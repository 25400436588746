import { useEffect, useRef, useState } from 'react'
import { DateTime } from "luxon";
import { BACKEND_URLS, ENDPOINT_HOST } from '../../../config';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { OrderHeader } from '../../../components/headers';
import { axiosClient } from '../../../services/axiosClient';
import toast from 'react-hot-toast';
import { getUser } from '../../../utils/helpers';
import useGetPermission from '../../../hooks/useGetPermission';
import { Spinner } from 'react-bootstrap';
import { useAppSelector } from '../../../Redux/hooks';


//@ts-ignore
window.DateTime = DateTime


function Order() {
  const [customParams, setCustomParams] = useState<{ [key: string]: string | number }>({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  // const [totalRows, setTotalRows] = useState(0);
  // const [perPage, setPerPage] = useState(100);
  // const [currentPage, setCurrentPage] = useState(1);
  const user = getUser()
  const navigate = useNavigate();
  const FRONTEND_ROUTES = useAppSelector(state => state.NavBarReducer.frontendRoutes);
  const { isLctRole, isLctAdminRole } = useGetPermission()

  const onChangeFilterText = (value: any, key: string) => {
    setCustomParams((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const onFilterApply = () => {
    // fetchData(currentPage);
    fetchData();
  };

  const columns = [
    {
      name: "Enroll Date",
      selector: (row: any) => row.orderDate?.split('T')[0],
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.orderDate?.split('T')[0]} </div>,
    },
    {
      name: "UserName",
      selector: (row: any) => row.user.username,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.user.username} </div>,
    },
    {
      name: "Name",
      selector: (row: any) => row.user.name,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.user.name} </div>,
    },
    {
      name: "Course",
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      >
        {row.course.name}
      </div>,
      selector: (row: any) => row.course.name, sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row: any) => row.totalAmount,
      sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        className='text-breaker'
      > {row.totalAmount} </div>,
    },
    {
      name: "Paid Amount",
      cell: (row: any) => {
        const totalAmount = row?.enrollmentPayment?.reduce((total: any, payment: any) => total + payment.amount, 0);
        return <span>{totalAmount}</span>;
      },
      selector: (row: any) => row?.enrollmentPayment?.reduce((total: any, payment: any) => total + payment.amount, 0), sortable: true,
    },
  ];

  const fetchData = async (
    // page: number, 
    // size = perPage
  ) => {
    const params = {
      // page,
      // limit: size,
      ...customParams,
    };
    setIsLoading(true);

    if (!isLctAdminRole && isLctRole && user?.user.id) {
      params.staffId = user.user.id;
      setCustomParams((prev) => ({ ...prev, staffId: user.user.id }))
    }

    try {
      const response = await axiosClient.get(`${ENDPOINT_HOST + BACKEND_URLS.ORDERS}`,
        {
          params,
        }
      );

      const ajaxResponse = handleAjaxResponse(response.data);
      setData(ajaxResponse.data);
      // setTotalRows(ajaxResponse.last_page * size);
    } catch (error: any) {
      toast.error("Error fetching data:", error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleAjaxResponse = (response: any) => {
    if (response && response.data) {
      return {
        data: response.data,
        last_page: response.total_pages || 1,
      };
    } else {
      toast.error("Invalid response format:", response);
      return { data: [] };
    }
  };

  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  //   fetchData(page);
  // };

  const handleRowClick = (row: any) => {
    navigate(`${FRONTEND_ROUTES?.BD?.ORDER}/${row.id}`);
  };

  // useEffect(() => {
  // fetchData(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='mx-3'>
      <h1>All Orders</h1>
      <OrderHeader onChangeFilterText={onChangeFilterText}
        onFilterApply={onFilterApply}
        customParams={customParams}
      // onFilterApply={onFilterApply}
      />

      {isLoading ? <div className='d-flex justify-content-center'><Spinner animation="border" variant="primary" /></div> :
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationPerPage={100}
          paginationRowsPerPageOptions={[100, 500, 1000]}
          // paginationServer
          // paginationTotalRows={totalRows}
          // onChangePage={handlePageChange}
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
          noDataComponent="No Data Set"
          // customStyles={{
          //   table: {
          //     style: {
          //       width: '90%',
          //       margin: 'auto'
          //     }
          //   }
          // }}
        />}
    </div>
  );
}

export default Order;
