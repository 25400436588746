import { PERMISSIONS_LIST } from '../utils/constants/permission-constant';
import usePermit from './usePermit';

function useGetPermission() {
    const { hasPermission } = usePermit();
    const permit = {
        isDoubtAdmin: hasPermission(PERMISSIONS_LIST.DOUBT_ADMIN),
        isDoubtFaculty: hasPermission(PERMISSIONS_LIST.DOUBT_FACULTY),
        canViewUser: hasPermission(PERMISSIONS_LIST.VIEW_USERS),
        canCreateUser: hasPermission(PERMISSIONS_LIST.CREATE_USER),
        canEditUser: hasPermission(PERMISSIONS_LIST.EDIT_USER),
        canAssignRolePermission: hasPermission(PERMISSIONS_LIST.ASSIGN_ROLE_PERMISSION),
        canDeleteStudent: hasPermission(PERMISSIONS_LIST.DELETE_STUDENT),
        canDeleteStaff: hasPermission(PERMISSIONS_LIST.DELETE_STAFF),
        isHasNotificationPermission: hasPermission(PERMISSIONS_LIST.SEND_NOTIFICATION),
        isOnboardingRole: hasPermission(PERMISSIONS_LIST.ONBOARDING),
        isMentorRole: hasPermission(PERMISSIONS_LIST.MENTOR),
        isMentorAdminRole: hasPermission(PERMISSIONS_LIST.MENTOR_ADMIN),
        isLctRole: hasPermission(PERMISSIONS_LIST.LCT),
        isLctAdminRole: hasPermission(PERMISSIONS_LIST.LCT_ADMIN),
        canCreateNode: hasPermission(PERMISSIONS_LIST.CREATE_NODE),
        canCreateSet: hasPermission(PERMISSIONS_LIST.CREATE_SET),
        canCreateCourse: hasPermission(PERMISSIONS_LIST.CREATE_COURSE),
        canCreateQuiz: hasPermission(PERMISSIONS_LIST.CREATE_QUIZ),
        canViewLiveDashboard: hasPermission(PERMISSIONS_LIST.LIVE_DASHBOARD),
        canAccessSubjectGroup: hasPermission(PERMISSIONS_LIST.SUBJECT_GROUP),
        canAccessSubject: hasPermission(PERMISSIONS_LIST.VIEW_SUBJECT),
        canAccessChapter: hasPermission(PERMISSIONS_LIST.VIEW_CHAPTER),
        canAccessContentGroup: hasPermission(PERMISSIONS_LIST.CONTENT_GROUP),
        canAccessExamTarget: hasPermission(PERMISSIONS_LIST.EXAM_TARGET),
        canAccessStudentClass: hasPermission(PERMISSIONS_LIST.STUDENT_CLASS),
        canCreateRole: hasPermission(PERMISSIONS_LIST.CREATE_ROLE),
        canCreatePermission: hasPermission(PERMISSIONS_LIST.CREATE_PERMISSION),
        isAdmin: hasPermission(PERMISSIONS_LIST.ADMIN),
    };

    return permit;
}

export default useGetPermission;
