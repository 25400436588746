import { FC, ReactNode, useMemo } from 'react';
import './styles/styles.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
    ErrorPage,
    Root,
    Login,
    PracticeQuiz,
    PracticeQuizEdit,
    User,
    PracticeSheetPage,
    ProofRead,
    ModulePdfPage,
    SolveCast,
    Practice,
    Admin,
    Content,
    Engagment,
    StudentParentReport,
    Setting,
    settingLoader,
} from './pages';
import { FRONTEND_URLS } from './config';
import Course, { loader } from './pages/content/course';
import Sets, { setLoader } from './pages/content/set';
import CreateSet, { editSetLoader } from './components/sets/create-set';
import CreateCourse, { createCourseLoader } from './components/course/create-course';
import { CreateOrderForm, Order, Payments } from './pages/bd';
import Students from './pages/students';
import QuizEdit from './components/Quiz/QuizEdit/QuizEdit';
import AllQuizzes from './components/Quiz/AllQuizzes';
import AllUsers from './pages/students/all-users';
import UploadQuizPage from './components/upload-quiz/page';
import CollectionPage, { collectionLoader } from './pages/content/collection';
import CreateCollection from './components/collection/create-collection';
import RolesPage from './pages/user/roles';
import PermissionsPage from './pages/user/permissions';
import Testimonials from './pages/admin/testimonials';
import { mapMenuToFrontendUrls } from './utils/helpers';
import { useAppSelector } from './Redux/hooks';

interface Route {
    path: string;
    element: ReactNode;
    errorElement: ReactNode;
    loader?: any;
    children: Route[];
}

const Routing: FC = () => {
    const LOCAL_STORAGE_FRONTEND_ROUTES = mapMenuToFrontendUrls();
    const REDUX_FRONTEND_ROUTES = useAppSelector(state => state.NavBarReducer.frontendRoutes);

    const createRoute = (): Route[] => {
        const FRONTEND_ROUTES = REDUX_FRONTEND_ROUTES?.length ? REDUX_FRONTEND_ROUTES : LOCAL_STORAGE_FRONTEND_ROUTES;
        if (!FRONTEND_ROUTES) return []

        const routes: Route[] = [];
        const addRoute = (
            path: string,
            element: ReactNode,
            errorMessage: string,
            loader: any = null,
            children: Route[] = []
        ): void => {
            if (path) {
                routes.push({
                    path,
                    element,
                    errorElement: <ErrorPage errorMessage={errorMessage} />,
                    ...(loader && { loader }),
                    children,
                });
            }
        };

        // Engagement Routes
        addRoute(FRONTEND_ROUTES?.ENGAGMENT?.DOUBT_TOOL, <Engagment.Doubts />, "Doubt tool error");
        addRoute(FRONTEND_ROUTES?.ENGAGMENT?.NOTIFICATION, <Engagment.Notification />, "Notification error", Engagment.NotificationLoader);

        // Admin Routes
        addRoute(FRONTEND_ROUTES?.PROFILE?.SETTING, <Setting />, "Setting page error", settingLoader);
        addRoute(FRONTEND_URLS?.ADMIN?.LEVEL + "/:levelId", <Admin.SubjectGroups />, "Error in loading subject groups", Admin.SubjectGroupLoader);
        addRoute(FRONTEND_ROUTES?.ENROLLMENT_ADMIN?.SERVICES, <Admin.Services />, "Error in loading services", Admin.serviceLoader);
        addRoute(FRONTEND_ROUTES?.ENROLLMENT_ADMIN?.PROCESSES, <Admin.Processes />, "Error in loading processes", Admin.processLoader);
        addRoute(FRONTEND_ROUTES?.ENROLLMENT_ADMIN?.HARDWARES, <Admin.Hardware />, "Error in loading hardware", Admin.haedwareLoader);
        addRoute(FRONTEND_ROUTES?.ADMIN?.EXAM_TARGETS, <Admin.ExamTargets />, "Error in loading exam targets", Admin.examTargetLoader);
        addRoute(FRONTEND_ROUTES?.ADMIN?.STUDENT_CLASSES, <Admin.StudentClasses />, "Error in loading student classes", Admin.studentClassLoader);
        addRoute(FRONTEND_ROUTES?.ENROLLMENT_ADMIN?.DEMO_COURSES, <Admin.DemoCourses />, "Error in loading demo courses");

        // Student Routes
        addRoute(FRONTEND_ROUTES?.STUDENT?.ENROLLED_STUDENTS, <Students.EnrolledStudents />, "Enrolled students error");
        addRoute(FRONTEND_ROUTES?.STUDENT?.ENROLLED_STUDENTS + '/:enrollmentId', <Students.StudentInfo />, "Student info error");
        addRoute(FRONTEND_ROUTES?.STUDENT?.ENROLLED_STUDENTS + '/:enrollmentId/:studentId', <Students.StudentInfo />, "Student info error");
        addRoute(FRONTEND_ROUTES?.BD?.ORDER, <Order />, "Order page error");
        addRoute(FRONTEND_ROUTES?.BD?.PAYMENTS, <Payments />, "Payments page error");
        addRoute(FRONTEND_ROUTES?.BD?.ORDER + '/:orderId', <CreateOrderForm />, "Order form error");
        addRoute(FRONTEND_ROUTES?.STUDENT?.USERS, <AllUsers />, "All users error");
        addRoute(FRONTEND_ROUTES?.STUDENT?.USERS + '/:id', <Students.UserDetails />, "User detail page error", Students.UserDetailsLoader);

        // Content Routes
        addRoute(FRONTEND_ROUTES?.CONTENTS?.NODE, <Content.Node.Node />, "Error in loading nodes", Content.Node.NodeLoader);
        addRoute(FRONTEND_ROUTES?.CONTENTS?.COURSES, <Course />, "Error in loading courses", loader);
        addRoute(FRONTEND_ROUTES?.CONTENTS?.COURSES + '/create-course', <CreateCourse />, "Error in creating course");
        addRoute(FRONTEND_ROUTES?.CONTENTS?.COURSES + '/:courseId', <CreateCourse />, "Error in editing course", createCourseLoader);
        addRoute(FRONTEND_ROUTES?.CONTENTS?.SETS, <Sets />, "Error in loading sets", setLoader);
        addRoute(FRONTEND_ROUTES?.CONTENTS?.SETS + '/create-set', <CreateSet />, "Error in creating set");
        addRoute(FRONTEND_ROUTES?.CONTENTS?.SETS + '/:setId', <CreateSet />, "Error in editing set", editSetLoader);
        addRoute(FRONTEND_ROUTES?.CONTENTS?.QUIZ, <AllQuizzes />, "Error in loading quizzes");
        addRoute(FRONTEND_ROUTES?.CONTENTS?.QUIZ + '/upload-quiz', <UploadQuizPage />, "Error in uploading quiz");
        addRoute(FRONTEND_ROUTES?.CONTENTS?.QUIZ + '/create-quiz', <QuizEdit />, "Error in creating quiz");
        // addRoute(FRONTEND_ROUTES?.CONTENTS?.QUIZ + '/create-practice-quiz', <QuizEdit />, "Error in creating practice quiz");
        // addRoute(FRONTEND_ROUTES?.CONTENTS?.QUIZ + '/edit-practice-quiz' + '/:quizId', <QuizEdit />, "Error in editing quiz");
        addRoute(FRONTEND_ROUTES?.CONTENTS?.QUIZ + '/edit-quiz' + '/:quizId', <QuizEdit />, "Error in editing quiz");
        addRoute(FRONTEND_ROUTES?.CONTENTS?.LIVE_MANAGMENT + '/:tab', <Content.LiveManagment />, "Error in loading live management");

        // Proofread & Module Pages
        addRoute(FRONTEND_ROUTES?.PROOF_READ + '/:quizId', <ProofRead />, "Error in proofreading");
        addRoute(FRONTEND_ROUTES?.MODULE_PDF + '/:quizId', <ModulePdfPage />, "Error in loading PDF module");

        // External (Commented out, but added back)
        addRoute(FRONTEND_ROUTES?.EXTERNAL?.IFRAME_VIEWER, <Content.IframeViewer />, "Error in iframe viewer");

        // Collection Routes
        addRoute(FRONTEND_ROUTES?.ADMIN?.COLLECTION, <CollectionPage />, "Error in loading collections", collectionLoader);
        addRoute(FRONTEND_ROUTES?.ADMIN?.COLLECTION + '/create', <CreateCollection />, "Error in creating collection");
        addRoute(FRONTEND_ROUTES?.ADMIN?.COLLECTION + '/edit' + '/:collectionId', <CreateCollection />, "Error in editing collection");

        // Testimonial Routes
        addRoute(FRONTEND_ROUTES?.ADMIN?.TESTIMONIAL, <Testimonials />, "Error in loading testimonials");
        addRoute(FRONTEND_ROUTES?.PRACTICE_QUIZ, <PracticeQuiz />, "Error in loading practice quiz", null, [
            {
                path: ':quizId',
                element: <PracticeQuizEdit />,
                errorElement: <ErrorPage errorMessage="Practice quiz edit error" />,
                children: [],
            },
        ]);

        // User Routes
        addRoute(FRONTEND_ROUTES?.USER, <User />, "User page error", null, [
            {
                path: FRONTEND_ROUTES?.PRACTISE + '/:quizId',
                element: <Practice />,
                errorElement: <ErrorPage errorMessage="Practice quiz error" />,
                children: [],
            },
            {
                path: FRONTEND_ROUTES?.PRACTISE_SHEET + '/:quizId',
                element: <PracticeSheetPage />,
                errorElement: <ErrorPage errorMessage="Practice sheet error" />,
                children: [],
            },
        ]);
        addRoute(FRONTEND_ROUTES?.ENROLLMENT_ADMIN?.ROLES, <RolesPage />, "Error in loading roles");
        addRoute(FRONTEND_ROUTES?.ENROLLMENT_ADMIN?.PERMISSIONS, <PermissionsPage />, "Error in loading permissions");

        return routes;
    };

    const DYNAMIC_ROUTES = useMemo(createRoute, [REDUX_FRONTEND_ROUTES, LOCAL_STORAGE_FRONTEND_ROUTES])

    const router = createBrowserRouter([
        {
            path: FRONTEND_URLS.ROOT,
            element: <Root />,
            errorElement: <ErrorPage errorMessage="All errors of the app come here." />,
            children: DYNAMIC_ROUTES
        },

        {
            path: FRONTEND_URLS.EXTERNAL.IFRAME_VIEWER,
            element: <Content.IframeViewer />,
            errorElement: <ErrorPage errorMessage="order page error" />,
            children: [],
        },

        {
            path: FRONTEND_URLS.CONTENTS.EDIT_NODE + '/:nodeId',
            element: <Content.Node.EditNode />,
            errorElement: <ErrorPage errorMessage="Error in loading content group" />,
            loader: Content.Node.EditNodeLoader,
            children: [
                {
                    path: 'content/:contentId',
                    element: <Content.Node.Content />,
                    errorElement: <ErrorPage errorMessage="Error in loading content group" />,
                    children: [],
                },
            ],
        },
        {
            path: FRONTEND_URLS.STUDENT.STUDENT_PARENT_REPORT + '/:studentId',
            element: <StudentParentReport />,
            errorElement: <ErrorPage errorMessage="order page error" />,
            children: [],
        },
        {
            path: FRONTEND_URLS.STUDENT.STUDENT_PARENT_REPORT,
            element: <StudentParentReport />,
            errorElement: <ErrorPage errorMessage="order page error" />,
            children: [],
        },
        {
            path: FRONTEND_URLS.LOGIN,
            element: <Login />,
            errorElement: <ErrorPage errorMessage="Login error" />,
            children: [],
        },
        {
            path: FRONTEND_URLS.USER_SOLVE_CAST,
            element: <SolveCast />,
            errorElement: <ErrorPage errorMessage="Solve cast error" />,
            children: [],
        },
    ]);

    return <RouterProvider router={router} />
};

export default Routing;