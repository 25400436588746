import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import testimonialService from '../../services/ApiServices/testimonial-service';
import { TestimonialPayload, TestimonialResp } from '../../types/testimonialTypes';
import MainBtn from '../../components/btn/main-btn';
import { Modal } from 'react-bootstrap';
import TestimonialForm from '../../components/testimonial/create-edit-testimonial';
import toast from 'react-hot-toast';
import { getMediaUrl } from '../../utils/helpers';

const typesMapping: any = {
    TESTIMONIAL: 'Testimonial',
    RESULT: 'Result'
}

const Testimonials = () => {
    const [data, setData] = useState<TestimonialResp[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [dataToEdit, setDataToEdit] = useState<null | TestimonialResp>(null);

    const fetchTestimonials = () => {
        testimonialService.getAllTestimonials().then((resp) => {
            if (resp.status === 'success') {
                setData(resp.data.testimonials);
            }
        });
    };

    useEffect(() => {
        fetchTestimonials();
    }, []);

    const columns: TableColumn<TestimonialResp>[] = [
        {
            name: 'Image',
            cell: (row) => (
                <img
                    src={getMediaUrl(row.imageUrl)}
                    alt={row.name}
                    style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                        borderRadius: '50px',
                    }}
                />
            ),
            selector: (row) => row.imageUrl,
        },
        {
            name: 'Type',
            cell: (row) => typesMapping[row.type] || row.type,
            selector: (row) => row.type,
        },
        {
            name: 'Name',
            cell: (row) => row.name,
            selector: (row) => row.name,
        },
        {
            name: 'Label',
            cell: (row) => row.label,
            selector: (row) => row.label || "NA",
        },
        {
            name: 'Message',
            cell: (row) => row.message,
            selector: (row) => row.message || "NA",
            style: {
                textOverflow: 'hidden',
            },
        },
        {
            name: 'VideoId',
            cell: (row) => row.videoId || '',
            selector: (row) => row.videoId || '',
        },
    ];

    const handleFormSubmit = async (formData: TestimonialPayload, id?: number) => {
        if (id) {
            const resp = await testimonialService.updateTestimonial(id, formData);
            if (resp.status == 'success') {
                toast.success(resp.message);
                setShowModal(false);
                fetchTestimonials();
                setDataToEdit(null);
            } else toast.error(resp.message);
        } else {
            const resp = await testimonialService.createTestimonial(formData);
            if (resp.status === 'success') {
                toast.success(resp.message);
                fetchTestimonials();
                setShowModal(false);
            } else toast.error(resp.message);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setDataToEdit(null);
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>Testimonials</h2>
            <DataTable
                columns={columns}
                data={data}
                pagination
                paginationPerPage={100}
                onRowClicked={(row) => {
                    // console.log(row);
                    setDataToEdit(row);
                    setShowModal(true);
                }}
            />
            <MainBtn onClick={() => setShowModal(true)} message='Create Testimonial'/>

            {/* Modal for creating new testimonial */}
            {showModal && <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${dataToEdit ? "Edit" : "Create New"} Testimonial`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TestimonialForm
                        onSubmit={handleFormSubmit}
                        submitLabel={dataToEdit ? "Edit" : "Create"}
                        initialData={dataToEdit || undefined}
                    />
                </Modal.Body>
            </Modal>}
        </div>
    );
};

export default Testimonials;
