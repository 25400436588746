import { useLoaderData, useNavigate } from 'react-router-dom';
import CourseService from '../../../services/ApiServices/course-service';
import CommonCard from '../../../components/cards/common.card';
import MainBtn from '../../../components/btn/main-btn';
import Title from '../../../components/common/title';
import { CourseLoader } from '../../../types/course.types';
import { useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useAppSelector } from '../../../Redux/hooks';

export async function loader() {
    const data = await CourseService.getAllCourses();
    return {
        courses: data.data.courses,
        total: data.meta.total,
        page: data.meta.page,
        limit: data.meta.limit,
    };
}

const Course = () => {
    const { courses, total } = useLoaderData() as CourseLoader;
    const navigate = useNavigate();
    const FRONTEND_ROUTES = useAppSelector(state => state.NavBarReducer.frontendRoutes);
    const [courseName, setCourseName] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    const filteredCourses = courses?.filter((c) => {
        const matchesName = c.name.toLowerCase().includes(courseName.toLowerCase());
        const matchesStatus = statusFilter ? c.status === statusFilter : true;
        return matchesName && matchesStatus;
    });

    return (
        <div className="pt-2">
            <div className="mx-4 mt-1 d-flex justify-content-between">
                <Title title="Courses" total={total} />
                <div className="d-flex align-items-center w-50 col-7 gap-3">
                    <Form.Group className="form-floating w-50">
                        <Form.Select
                            className="form-select"
                            id="statusSelect"
                            onChange={(e) => setStatusFilter(e.target.value)}
                        >
                            <option value="">All Status</option>
                            <option value="DRAFT">Draft</option>
                            <option value="PUBLISHED">Published</option>
                        </Form.Select>
                        <Form.Label htmlFor="statusSelect">Filter by Status</Form.Label>
                    </Form.Group>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Search for course"
                        className="w-50"
                    >
                        <Form.Control
                            type="text"
                            onChange={(e) => setCourseName(e.target.value)}
                            placeholder="JEE prahar 2.0"
                        />
                    </FloatingLabel>
                </div>
            </div>
            <div className="mx-4 mt-4">
                <div style={{ display: 'flex', flexWrap: 'wrap' }} className="gap-2">
                    {filteredCourses.map((itm) => (
                        <CommonCard key={itm.id} data={itm} type="courses" status={itm.status} />
                    ))}
                </div>
            </div>
            <MainBtn onClick={() => navigate(`${FRONTEND_ROUTES?.CONTENTS?.COURSES}/create-course`)} message="Add new Course" />
        </div>
    );
};

export default Course;
