import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Form, Button, Row, Col, InputGroup, Modal, ProgressBar } from 'react-bootstrap';
import './styles.css';
import { TestimonialPayload, TestimonialResp } from '../../types/testimonialTypes';
import { pdfUploadHelper, uploadVideoOnVdoCipherDirect } from '../../utils/helpers';
import toast from 'react-hot-toast';
// import UploadPromotionalVideo from '../course/upload-video';
import { VideoSource } from '../../types/types';
import generalService from '../../services/ApiServices/general-service';
import { axiosClient } from '../../services/axiosClient';
import { BACKEND_URLS } from '../../config';

interface TestimonialFormProps {
    initialData?: TestimonialResp;
    onSubmit: (data: TestimonialPayload, e?: number) => void;
    submitLabel?: string;
}

interface SelectedFilesInterface {
    imgFile: File | null;
    videoFile: File | null;
}

// const platforms = [{ optionValue: 'VDOCIPHER_SECURE', optionName: 'SECURE' }];

const TestimonialForm: React.FC<TestimonialFormProps> = ({
    initialData,
    onSubmit,
    submitLabel = 'Save',
}) => {
    const [formData, setFormData] = useState<TestimonialPayload>({
        name: initialData?.name || '',
        label: initialData?.label || '',
        message: initialData?.message || '',
        videoId: initialData?.videoId || undefined,
        imageUrl: initialData?.imageUrl || '',
        type: initialData?.type || "TESTIMONIAL"
    });
    const [isLoading, setIsLoading] = useState({ flag: false, msg: '' });
    const [selectedFiles, setSelectedFiles] = useState<SelectedFilesInterface>({ imgFile: null, videoFile: null })
    // const [showVideoModel, setShowVideoModel] = useState(false);

    const resetStates = () => {
        setFormData({
            name: initialData?.name || '',
            label: initialData?.label || '',
            message: initialData?.message || '',
            videoId: initialData?.videoId || undefined,
            imageUrl: initialData?.imageUrl || '',
            type: initialData?.type || "TESTIMONIAL"
        })
        setIsLoading({ flag: false, msg: '' });
        setSelectedFiles({ imgFile: null, videoFile: null })
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === 'type') {
            setFormData((prev) => ({
                ...prev,
                label: "",
                message: "",
                [name]: value,
            }));
            return;
        }
        setFormData((prev) => ({
            ...prev,
            [name]: name === 'videoId' ? Number(value) : value,
        }));
    };

    const handleUploadVideo = async (file: File) => {
        try {
            const { videoId, fileName, s3Url } = (await uploadVideoOnVdoCipherDirect({
                file,
                vdoCipherId: VideoSource.VDOCIPHER_SECURE,
            })) as { videoId: string; fileName: string, s3Url: string };

            const otpResp = await generalService.getVideoOtp({
                source: 'VDOCIPHER_SECURE',
                videoId: videoId,
                otpDuration: 24 * 60 * 60 * 365 * 10,
            });
            const otp = otpResp.data.otp;
            const playbackInfo = otpResp.data.playbackInfo;
            const response = await axiosClient.post(BACKEND_URLS.ASSET_CREATE, {
                source: VideoSource.VDOCIPHER_SECURE,
                internalName: file.name,
                fileName: file.name,
                durInSec: 0,
                videoId: `${videoId}`,
                vdocipherOtp: otp,
                playbackInfo,
                archiveUrl: s3Url
            });
            const data = response.data.data;
            const assetId = data.id;
            return { id: assetId, fileName }
        } catch (error) {
            toast.error(JSON.stringify(error))
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!formData.name) return toast.error('Name is required');
        if (formData.type === "TESTIMONIAL" && !formData.label) return toast.error('Label is required');
        if (formData.type === "TESTIMONIAL" && !formData.message) return toast.error('Message is required');
        if (submitLabel !== 'Edit' && !selectedFiles.imgFile) return toast.error('Image is required');
        setIsLoading({ flag: true, msg: '' });
        let imageUrl;
        let videoId;
        try {
            if (selectedFiles.imgFile) {
                setIsLoading({ flag: true, msg: 'uploading image...' });
                const uploadedImgUrl = await pdfUploadHelper(selectedFiles.imgFile);
                imageUrl = uploadedImgUrl;
            }
            if (selectedFiles.videoFile) {
                setIsLoading({ flag: true, msg: 'uploading video...' });
                const uploadedVideoData = await handleUploadVideo(selectedFiles.videoFile);
                videoId = uploadedVideoData?.id
            }
            const payload = {
                ...formData,
                imageUrl,
                videoId
            }
            setIsLoading({ flag: true, msg: 'submiting data...' });
            if (initialData?.id) onSubmit(payload, initialData.id);
            else onSubmit(payload);
            resetStates();
        } catch (error) {
            toast.error(JSON.stringify(error))
        } finally {
            setIsLoading({ flag: false, msg: '' });
        }
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const key = e.target.name
        if (files?.length) {
            const file = files[0];
            setSelectedFiles((prev) => ({
                ...prev,
                [key]: file
            }));
        }
    };

    return (
        <Form className="testimonial-form-container" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Select required value={formData?.type} name='type' onChange={handleChange}>
                    <option value="TESTIMONIAL">Testimonial</option>
                    <option value="RESULT">Result</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="testimonialName" className="mb-3">
                <Form.Label>Student Name</Form.Label>
                <InputGroup>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter Student name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </InputGroup>
            </Form.Group>

            {formData?.type === 'TESTIMONIAL' &&
                <Form.Group controlId="testimonialLabel" className="mb-3">
                    <Form.Label>HighLight</Form.Label>
                    <InputGroup>
                        <Form.Control
                            required
                            type="text"
                            name="label"
                            value={formData.label}
                            onChange={handleChange}
                        />
                    </InputGroup>
                </Form.Group>}

            {formData?.type === 'TESTIMONIAL' &&
                <Form.Group controlId="testimonialMessage" className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        rows={4}
                        placeholder="Enter the testimonial message..."
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    />
                </Form.Group>}

            <Form.Group controlId="testimonialImageUrl" className="mb-3">
                <Form.Label>Image</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="file"
                        accept="image/*"
                        name="imgFile"
                        onChange={handleFileChange}
                    />
                </InputGroup>
                <small style={{ fontSize: 10 }}>{formData.imageUrl}</small>
            </Form.Group>

            <Form.Group
                controlId="testimonialVideoId"
                className="mb-3 border border-light-subtle rounded p-2"
            >
                <Form.Label className="fw-bold">Video (Optional)</Form.Label>
                <div className="d-flex align-items-center">
                    {formData.videoId && (
                        <span className="me-3">Current Video ID: {formData.videoId}</span>
                    )}
                </div>
                <div className="p-2">
                    <Form.Group className="mb-3 row align-items-center">
                        <Form.Label className="col-sm-3 col-form-label">
                            Upload Video File
                        </Form.Label>
                        <div className="col-sm-9">
                            <Form.Control
                                type="file"
                                name="videoFile"
                                onChange={handleFileChange}
                                accept="video/mp4,video/x-m4v,video/*"
                            />
                        </div>
                    </Form.Group>
                </div>
            </Form.Group>
            {isLoading.flag &&
                <ProgressBar
                    className='mb-2'
                    now={100}
                    label={`${isLoading.msg}`}
                    animated
                    striped
                />
            }
            <Row>
                <Col className="text-end">
                    <Button variant="success" type="submit" className="px-4" disabled={isLoading.flag}>
                        {submitLabel}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default TestimonialForm;
