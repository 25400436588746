import { SessionInterface } from '../../types/LiveTypes';
import { BulkUpdateSessions, ZoomMeeting } from '../../types/session-types';
import ApiService, { ApiResponse } from './api-service';

class sessionService extends ApiService {
    constructor() {
        super('/sessions');
    }

    getSessions(query: string = ''): ApiResponse<SessionInterface[]> {
        return this.getData(query);
    }

    getSessionsFromZoom(): ApiResponse<ZoomMeeting[]> {
        return this.getData(`/sessions-from-zoom`);
    }

    // bulkUpdateSessions(data: BulkUpdateSessions): ApiResponse<any> {
    //     return this.putData('/bulk-session-update', data);
    // }
}

export default new sessionService();
