import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { axiosClient } from '../../../services/axiosClient';
import { BACKEND_URLS, ENDPOINT_HOST } from '../../../config';
import PaymentsHeader from '../../../components/headers/payement.header';
import toast from 'react-hot-toast';
import userService from '../../../services/user-service';
import { User } from '../../../types/user-profille-types';
import { PaymentFilter } from '../../../types/payments';
import moment from 'moment/moment';
import ViewImage from '../../../components/modals/view-image-modal';
import { getUser } from '../../../utils/helpers';
import useGetPermission from '../../../hooks/useGetPermission';

function Payments() {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [perPage, setPerPage] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [staffUsers, setStaffUsers] = useState<User[]>([]);
  const [isImagePreview, setIsImagePreview] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [totalAmount, setTotalAmount] = useState<number | null>(null);

  const { isLctAdminRole } = useGetPermission();
  const user = getUser();
  const staffId = isLctAdminRole ? undefined : user?.user.id;

  const initialFrom = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const initialTo = moment().format('YYYY-MM-DD');

  // Filter states
  const [filters, setFilters] = useState<PaymentFilter>({
    collectedBy: "",
    status: "Pending",
    paymentType: "NA",
    phase: "",
    search: "",
    from: initialFrom,
    to: initialTo,
    isVerified: false,
  });

  const fetchStaff = async () => {
    try {
      const response = await userService.getStaff();
      setStaffUsers(response.data?.users)
    } catch (error) {
      toast.error(JSON.stringify(error))
    }
  }

  const fetchAllEnrollments = async (page: number) => {
    setLoading(true);
    try {
      const response = await axiosClient.get(`${ENDPOINT_HOST + BACKEND_URLS.PAYMENTS}`, {
        params: {
          page,
          limit: perPage,
          status: filters.status?.toUpperCase(),
          isVerified: filters.isVerified,
          from: filters.from,
          to: filters.to,
          search: filters.search || undefined,
          mode: filters.paymentType !== 'NA' ? filters.paymentType : undefined,
          staffId
        },
      });

      setData(response.data.data);
    } catch (error: any) {
      toast.error("Error fetching enrollments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    fetchAllEnrollments(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    fetchAllEnrollments(page);
  };

  const onFilterApply = () => {
    setResetPaginationToggle(true);
    setTotalAmount(null);
    fetchAllEnrollments(1);
  };

  const onChangeFilterText = (value: string, key: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const getCollectedByName = (row: any) => {
    const staffId = row.staffId || null
    if (staffId) {
      return staffUsers?.find((staffUser: User) => staffUser?.id === staffId)?.name || "NA"
    }
    return "NA"
  }

  const handleShowImage = (url: string) => {
    setImageUrl(url);
    setIsImagePreview(true);
  }

  const handleVerifyPayment = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    axiosClient.put(`${ENDPOINT_HOST + BACKEND_URLS.PAYMENTS}/${id}`, {
      isVerified: event.target.checked
    }).then(() => {
      // fetchAllEnrollments(1);
      setData((prevData: any) => {
        const newData = prevData.map((row: any) => {
          if (row.id === id) {
            return { ...row, isVerified: !row.isVerified }
          }
          return row;
        });
        return newData;
      })
      toast.success("Payment verified successfully");
    }).catch((error) => {
      toast.error("Error verifying payment:", error);
    });
  }

  const handleCommentChange = (event: React.FocusEvent<HTMLInputElement>, id: number) => {
    const comment = event.target.value;
    if (comment.length > 0) {
      axiosClient.put(`${ENDPOINT_HOST + BACKEND_URLS.PAYMENTS}/${id}`, {
        comment
      }).then(() => {
        toast.success("Comment updated successfully");
        // fetchAllEnrollments(1);
      }).catch((error) => {
        toast.error("Error updating comment:", error);
      });
    }
  }

  const getTotalAmount = () => {
    const totals = data.reduce((acc: number, curr: any) => acc + curr.amount, 0);
    setTotalAmount(totals.toLocaleString("en-IN") || 0);
  }

  const getMeta = (row: any) => {
    if (row.enrollment.user.userContacts?.length > 0) {
      return (
        <span className='py-1'>
          {row.enrollment.user.userContacts
            .map((ucon: any) => ucon.value)
            .join(', ')}
        </span>
      );
    }
    return <span>NA</span>;
  }

  const handleResetFilters = () => {
    setFilters({
      collectedBy: "",
      status: "Pending",
      paymentType: "",
      phase: "",
      search: "",
      from: initialFrom,
      to: initialTo,
      isVerified: false,
    });
  }



  const columns = [
    {
      name: "Payment Date",
      selector: (row: any) => row?.receivedOn, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row?.receivedOn?.split('T')[0] || "NA"} </div>,
    },
    {
      name: "User Name",
      selector: (row: any) => row.enrollment.user.username, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.enrollment.user.username} </div>,
    },
    {
      name: "Name",
      selector: (row: any) => row.enrollment.user.name, sortable: true, cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.enrollment.user.name} </div>,
    },
    {
      name: "Course",
      selector: (row: any) => row.enrollment.course.name, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.enrollment.course.name} </div>,
    },
    // {
    //   name: "Current End Date",
    //   selector: (row: any) => 'na', sortable: true,
    //   cell: (row: any) => <div
    //     data-tag="allowRowEvents"
    //     style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
    //   > {'na'} </div>,
    // },
    {
      name: "Amount",
      selector: (row: any) => row.amount, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.amount} </div>,
    },
    {
      name: "Payment Type",
      // selector: (row: any) => row.mode, sortable: true,
      cell: (row: any) => <div
        data-tag="allowRowEvents"
        style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}
      > {row.mode} </div>,
    },
    // {
    //   name: "Status",
    //   cell: (row: any) => (
    //     <span>
    //       {row.status === "PENDING" ? 'Pending' : 'Completed'}
    //     </span>
    //   ),
    //   sortable: false,
    // },
    { name: "LCT", selector: getCollectedByName, sortable: true },
    { name: 'Verified', cell: (row: any) => <input type='checkbox' disabled={!isLctAdminRole} checked={row?.isVerified} onChange={(event) => handleVerifyPayment(event, row.id)} /> },
    {
      name: 'Payment Proof', cell: (row: any) =>
        <Button
          variant="link"
          onClick={() => handleShowImage(row.proofOfPaymentUrl || "")}
          style={{ whiteSpace: 'nowrap', padding: 0 }}
        >
          Show Proof
        </Button>
    },
    { name: 'Meta', cell: (row: any) => getMeta(row) },
    {
      name: 'Comment', grow: 2, cell: (row: any) =>
        <Form.Control
          type='text'
          defaultValue={row?.comment}
          placeholder='Comment'
          onBlur={(event) => handleCommentChange(event as React.FocusEvent<HTMLInputElement>, row.id)}
        />
    }
  ];

  useEffect(() => {
    fetchAllEnrollments(1); // Fetch data on mount
  }, []);

  // useEffect(() => {
  //   fetchStaff();
  // }, [])

  return (
    <div className='mx-3'>
      <h1>All Payments</h1>
      <PaymentsHeader
        onChangeFilterText={onChangeFilterText}
        onFilterApply={onFilterApply}
        handleResetFilters={handleResetFilters}
        filters={filters}
      />
      <div className='d-flex gap-2 justify-content-end align-items-center'>
        <span>Total Amount: </span>
        {totalAmount === null && <Button size='sm' onClick={getTotalAmount}>Show Total</Button>}
        {totalAmount !== null && <span>{totalAmount}</span>}
      </div>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[100, 500, 1000]}
        noDataComponent="No Data Set"
        paginationServer
        highlightOnHover
        pointerOnHover
        // onRowClicked={(row) => console.log("Row clicked:", row)}
        paginationResetDefaultPage={resetPaginationToggle}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationTotalRows={totalRows}
      />
      <ViewImage
        show={isImagePreview}
        onHide={() => setIsImagePreview(false)}
        imageUrl={imageUrl}
      />
    </div>
  );
}

export default Payments;