const DEV_BASE_URL = 'https://dev-api.esaral.com';

const isDev = process.env.REACT_APP_NODE_ENV === 'development';

export const STUDENT_APP_HOST = isDev ? 'http://192.168.0.157:5050' : 'https://app.esaral.com';
export const ENDPOINT_HOST = isDev
    ? `${DEV_BASE_URL}/admin/v1`
    : 'https://dev-api2.esaral.com/admin/v1';

export const STUDENT_ENDPOINT_HOST = isDev
    ? `${DEV_BASE_URL}/v1`
    : 'https://dev-api2.esaral.com/v1';

const FRONTEND_URLS = {
    ROOT: '/',
    QUIZ_v2: 'quiz',
    LOGIN: '/login',

    ORDER: '/bd/order',
    ALL_USERS: '/student/users',
    BD: {
        ORDER: '/bd/order',
        PAYMENTS: '/bd/payments',
    },

    ENGAGMENT: {
        DOUBT_TOOL: '/engagement/doubts',
        NOTIFICATION: '/engagement/notification',
    },

    STUDENT: {
        ALL_USERS: '/student/users',
        ENROLLED_STUDENTS: `/student/enrolled-students`,
        STUDENT_ALL_USERS: `/student/all-users`,
        STUDENT_PARENT_REPORT: `/user/progress-report`,
    },

    ADMIN: {
        LEVEL: 'admin/level',
        SERVICES: 'admin/services',
        PROCESSES: 'admin/processes',
        HARDWARES: 'admin/hardwares',
        EXAM_TARGETS: 'admin/exam-target',
        STUDENT_CLASSES: 'admin/student-class',
        DEMO_COURSES: 'admin/demo-courses',
        TESTIMONIAL: 'admin/testimonials',
        ADMIN_ACADMIC: '/academic-admin',
        COLLECTION: '/contents/collection',
        CREATE_COLLECTION: '/contents/collection/create',
        EDIT_COLLECTION: '/contents/collection/edit',
        // SERVICES: '/enrollment-admin/services',
        // PROCESSES: '/enrollment-admin/processes',
        // HARDWARES: '/enrollment-admin/hardwares',
        // DEMO_COURSES: '/enrollment-admin/demo-courses',
    },

    ROLES: 'roles',
    PERMISSIONS: 'permissions',
    CONTENTS: {
        NODE: 'contents/node',
        CREATE_NODE: '/contents/create-node',
        EDIT_NODE: '/edit-node',
        COURSES: '/contents/courses',
        CREATE_COURSE: '/contents/create-course',
        EDIT_COURSE: '/contents/edit-course',
        SETS: '/contents/sets',
        CREATE_SET: '/contents/create-set',
        EDIT_SET: '/contents/edit-set',
        ASSET: 'content',

        QUIZ: '/contents/quiz',
        UPLOAD_QUIZ: `/content/upload-quiz`,

        CREATE_QUIZ: '/contents/create-quiz',
        EDIT_QUIZ: '/contents/edit-quiz',

        CREATE_PRACTISE_QUIZ: '/contents/create-practice_quiz',
        PRACTICE_QUIZ_EDIT: '/contents/practice-practice_quiz',
        // PRACTICE_QUIZ: '/contents/practice-quiz',
        // EDIT_PRACTICE_QUIZ_QUE: '/practice-quiz/questions',

        LIVE_MANAGMENT: '/contents/live-mangment',
        COLLECTION: '/contents/collection',
        CREATE_COLLECTION: '/contents/collection/create',
        EDIT_COLLECTION: '/contents/collection/edit',
    },
    PRACTICE_QUIZ: '/practice-quiz',

    SETTING: '/setting',

    EXTERNAL: {
        STUDENT_APP: {
            BASE: isDev
                ? // false
                  'http://localhost:5050'
                : 'https://app.esaral.com',

            QUIZ: '/quiz',
            PRACTICE: '/user/practice',
        },

        IFRAME_VIEWER: '/iframe-viewer',
    },

    // ------------------ Dpresated ---------------------- //

    CONTENT: 'content',
    QUIZ: 'quiz',
    QUIZ_START: '/user/quiz-sheet',
    QUIZ_RESULT: `/user/quiz-sheet/result`,

    QUIZ_EDIT: '/content/quiz/:quizId',
    EDIT_QUIZ_QUE: 'quiz/questions',

    PROOF_READ: '/proof-read',

    //Student side pages
    USER_PRACTICE: '/user/practice',
    USER_PRACTICE_QUIZ: '/user/practice/:quizId',
    USER_SOLVE_CAST_V2: 'solve-cast',
    USER_SOLVE_CAST: '/solve-cast/:quizId',

    TEST_ROOT: '/scholarship-quiz/',

    TEST_RESULT: '/content/quiz/result/',
    PRACTISE_QUIZ: '/practice-quiz',

    USER: 'user',

    PRACTISE: 'practice',

    PRACTISE_SHEET: 'practice-sheet',
    MODULE_PDF: 'module-pdf',
};

const BACKEND_URLS = {
    SEND_OTP: '/auth/send-otp',
    VERIFY_OTP: '/auth/verify-otp',

    LEVEL: '/level/',
    NODES: '/nodes',

    CONTENT: '/content',

    ASSET_CREATE: '/asset/upload-video',
    ASSET_CREATE_PDF: '/asset/upload-pdf/',
    ASSET_GET_PDF: '/asset/pdf/',
    GET_USERS: '/users',
    SERVIECES: '/services',
    TARGETS: '/targets',
    HARDWARS: '/hardwares',

    UPLOADS_PDF: '/uploads',

    UPLOADS: '/uploads/vdocipher-upload-credentials',

    FETCH_VIDEO_DETAILS: '/content/fetch-video-details',
    VDOCIPHER_OTP: '/content/vdo-cipher-otp',

    PROCESSESS: '/processes',
    STUDENT_CLASSES: '/student-classes',

    CREATE_SESSION: '/sessions',
    ZOOM_USERS: '/sessions/users',

    QUIZ: '/quiz',

    ENROLLMENT_MENTOR_PROCESS: `/enrollments/`,

    CONTENT_USAGE: '/content/content-usage',

    ENROLLMENT: '/enrollments',
    USER_ENROLLMENTS: '/user-enrollments',

    COURSES: {
        path: '/courses',
        children: {
            CLASS_TARGET: '/class-targets',
        },
    },

    NOTIFICATIION: '/notifications',

    USERS: {
        path: '/users',
        children: {
            USER_PROFILE: '/user-profile',
        },
    },
    ORDERS: '/enrollments/orders',
    PAYMENTS: '/enrollments/payments',
};

export { FRONTEND_URLS, BACKEND_URLS };

// ----------------------------------- Deprestaed ------------------------------------------ //

// export const BASE_URL = "https://development.esaral.com";
// export const BASE_URL = "https://api.esaral.com";
export const BASE_URL = 'http://localhost:3000/v1';

export const TOKEN_KEY = 'tkn';
export const USER_KEY = 'usr';

export const motivationVideosIds = [
    '908526510',
    '908526510',
    '908526510',
    '908526510',
    '908526510',
    '908526510',
];

export const MEDIA_BASE_URL = 'https://myesaralbucket.s3.amazonaws.com/';

export const URLS = {
    LOGIN: '/v1/users/staff/login',
    NEW_LOGIN: '/v2/users/staff/login',
    DASHBOARD: '/v1/courses/dashboard',
    NODE_CHILDREN: '/v2/contents/get-node-children',
    NODE_CHILDREN_CRUD: '/v2/contents/crud-node-children',
    ATTACH_NODE_CHILDREN: '/v2/contents/attach-node-children',
    NODE_INFO: '/v2/contents/get-node-info',
    CRUD_NODE_CONTENT: '/v2/contents/crud-node-content',
    NODE_CONTENT_INFO: '/v2/contents/get-node-content',
    CONTENT_LIST: '/v2/contents/get-content-for-attachment',
    UPLOAD_QUIZ: '/v2/quizes/create-quiz-upload',
    GET_QUIZ: '/v2/quizes/get-quiz-details',
    REMOVE_NODE_LINK: '/v2/contents/remove-node-link-api',
    COPY_NODE_LINK: '/v2/contents/copy-node-tree',
    VIDEO_OTP: '/v2/contents/get-video-otp',
    UPDATE_PARENT_ID: '/v2/contents/update-parent-node-link',

    //SubCourses
    ALL_SUBCOURSE_NODES: '/v2/courses/all-subcourse-nodes',

    //User info urls:
    GET_USER_PROFILE: 'v2/users/user-profile',
    GET_USER_DEVICES: 'v2/users/user-devices',
    GET_ALL_CLASSES: '/v2/general/get-all-classes',
    USER_INFO: '/v2/users/home',

    // Notification related urls
    CREATE_NOTIFICATION: '/v1/notifications/create-campaign',
    NOTIFICATION_META_DATA: '/v1/notifications/get-meta-data',

    //reports related urls
    ADMIN_STATS: '/v1/reports/content-consumption-stats',
    ENCRYPT_PDF: '/v2/contents/encrypt-pdf',

    //mentorship related urls
    UPLOAD_TIMETABLE: '/v1/mentorship/upload-timetable',
    GET_TIMETABLE: '/v1/mentorship/get-timetable',
    UPLOAD_USER_TIMETABLE: '/v1/mentorship/upload-user-timetable',
    DELETE_USER_TIMETABLE: '/v1/mentorship/delete-user-timetable',

    MENTOREDENROLLMENTS: '/v2/users/mentorship/enrolled-students',
    ENROLL_STUDENTS_DETAIL: '/v2/users/enrolled-student-details',
    STUDENT_ONBOARDING: '/v1/enrollment/get-onboarding-processes',
    UPDATE_ONBOARDING_STATUS: '/v1/enrollment/update-onboarding-process-status',
    ENROLLMENTS_MENTORSHIP_DATA: '/v1/mentorship/enrollment-mentor-data',
    ADD_CALL_LOG: '/v1/mentorship/add-mentor-call-log',
    USER_CONTENT_USAGES: '/v2/users/user-content-usage',

    GET_ALL_COURSES_PHASE: '/v2/courses/get-all-courses-phase',

    ASSIGN_MENTOR: '/v1/mentorship/assign-mentor',

    //enrollment related urls
    GET_STATES: '/v1/enrollment/get-indian-states',
    SEARCH_USER: '/v2/users/search-users',
    GET_COURSES: '/v1/enrollment/get-course-list',
    GET_ENROLLMENT_DETAILS: '/v1/enrollment/get-enrollment',
    ENROLL_STUDENT: '/v1/enrollment/create-enrollment',

    ALL_ENROLLMENTS: 'v1/enrollment/get-all-enrollments',
    ALL_PAYMENTS: 'v1/enrollment/get-all-payments',

    //Live Sessions API
    ALL_SESSIONS: '/v1/live/crud-live-sessions',
    ALL_SERIES: '/v1/live/crud-live-series',
    VIDEO_PLATFORM: '/v1/live/get-video-plateforms',
    CRUD_LIVE_SESSION: '/v1/live/crud-live-sessions',
    GET_SESSION_LAUNCH_URL: '/v1/live/get-session-launch-url',
    GET_ALL_SUBJECTS_CHAPTER: '/v2/contents/get-subcourse-chapters',
    UPLOAD_VIDEO_ON_VDOCIPHER: '/v1/live/upload-video-on-vdocipher',
    GET_VIDEO_DETAILS_FROM_VDOCIPHER: '/v1/live/get-vdocipher-upload-credentials',
    LIVE_SESSION_USAGE: '/v1/live/get-live-usage',
    ATTACH_SERIES_SESSION: '/v1/live/attach-session-series',
    //Doubts API
    ALL_DOUBTS: '/v1/doubts/get-all-doubts',
    UPDATE_DOUBT: '/v1/doubts/crud-doubt',
    ALL_MESSAGES: '/v1/doubts/get-doubt-messages',
    SEND_MESSAGE: '/v1/doubts/send-message',
    DELETE_MESSAGE: '/v1/doubts/delete-message',

    //QUIZ API
    CRUD_QUIZ: '/v2/quizes/crud-quiz',
    GET_QUIZ_QUE: '/v2/quizes/get-quiz-question-details',
    GET_QUESTION: '/v2/quizes/get-question-details',
    CRUD_QUESTION: '/v2/quizes/crud-question',
    CRUD_QUIZ_SECTION: '/v2/quizes/crud-quiz-section-api',
    ATTACH_QUESTIONS: '/v2/quizes/attach-quiz-questions',
    ATTACH_QUE_VIDEO_SOL: '/v2/quizes/upload_video_solution',
    REORDER_QUE: '/v2/quizes/reorder-questions',
    SEARCH_QUESTION: '/v2/quizes/search-questions',

    GET_QUIZ_DETAILS: `${BASE_URL}/v2/quizes/get-quiz-details`,
    QUIZ_STUDENT_ATTEMPT: `${BASE_URL}/v2/quizes/quiz-student-attempt`,
    GET_QUIZ_RESULTS: `${BASE_URL}/v2/quizes/get-quiz-results`,
    GET_QUIZ_ATTEMPTS: `${BASE_URL}/v2/quizes/get-all-quiz-attempts`,
    QUIZ_REPORT_CSV: `${BASE_URL}/v2/quizes/quiz-report-csv`,

    UPLOAD_QUESTION_FILE: '/v2/quizes/upload-quiz-questions-from-file',

    NODE_TREE: 'v2/contents/get-node-tree',

    GET_FACULTIES: '/v2/users/get-faculties',
    GET_MENTORS: '/v2/users/get-mentors',
    GET_ALL_PERMISSION: '/v2/users/get-user-permissions',

    GET_ALL_TEST_ATTEMPTS: '/v2/quizes/get-all-attempts',
};
