import { useLoaderData, useNavigate } from 'react-router-dom';
import Title from '../common/title';
import CustomUpload from '../ModulePDF/Upload';
import SetService from '../../services/ApiServices/set-service';
import SetNodes from './set-nodes';
import { useState } from 'react';
import { SET_TYPE, SetItem } from '../../types/setTypes';
import { reciveDateTimeFormate, validateString } from '../../utils/helpers';
import SpecialButton from '../btn/special-btn';
import { MEDIA_BASE_URL } from '../../config';
import toast from 'react-hot-toast';
import CustomCheckbox from '../common/custom-checkbox';
import { useAppSelector } from '../../Redux/hooks';

const selectOptions = [
    {
        label: 'Academic',
        value: 'ACADEMIC',
    },
    {
        label: 'Mentorship',
        value: 'NON_ACADEMIC',
    },
    {
        label: 'Doubt Solving',
        value: 'DOUBT_SOLVING',
    },
];

export const editSetLoader = async ({ _, params }: any) => {
    const setId = params?.setId;
    if (!setId) return null;
    return SetService.getById(setId).then((res) => res.data.set);
};

const CreateSet = () => {
    const data = useLoaderData() as SetItem;
    const navigate = useNavigate();
    const FRONTEND_ROUTES = useAppSelector(state => state.NavBarReducer.frontendRoutes);
    const [setDetails, setSetDetails] = useState(
        data || {
            name: '',
            thumbnail: '',
            type: SET_TYPE.ACADEMIC,
            isStatic: true,
        }
    );

    const handleChange = (name: string, value: any) => {
        setSetDetails((prev) => ({ ...prev, [name]: value }));
    };

    const submitSet = () => {
        if (!validateString(setDetails.name)) return toast.error('Set name is required*');
        const payload = {
            name: setDetails.name.trim(),
            thumbnail: setDetails.thumbnail,
            type: setDetails.type,
            isStatic: setDetails.isStatic,
            ...(setDetails.endDate && {
                endDate: new Date(setDetails.endDate).toISOString(),
            }),
        };
        if (data) {
            const id = data.id;
            SetService.updateSet(id, payload).then((res) => toast.success(res.message)).catch((err) => toast.error(JSON.stringify(err)));
        } else {
            SetService.createSet(payload).then((res) => {
                toast.success(res.message);
                if (res.status === 'success') {
                    navigate(`${FRONTEND_ROUTES?.CONTENTS?.SETS}/${res.data.id}`, { replace: true });
                }
            });
        }
    };

    return (
        <div className="p-3">
            <Title title={data ? 'Update set' : 'Create a new Set'} />
            <div className="row">
                <div className="col-md-3">
                    <CustomUpload
                        url={MEDIA_BASE_URL + setDetails.thumbnail}
                        onUploaded={(url) => handleChange('thumbnail', url)}
                    />
                </div>
                <div className="col-md-9 row col-12">
                    <div className="row mt-4 col-12 align-items-baseline">
                        <label className="form-label col-md-2 col-4">Name :</label>
                        <div className="col-md-10">
                            <input
                                name="name"
                                value={setDetails.name}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                required
                                className="form-control"
                                placeholder="Set name here..."
                            />
                        </div>
                    </div>
                    <div className="row mt-2 gap-md-2 col-md-6  align-items-baseline">
                        <label htmlFor="start-date" className="col-5 col-md-4">
                            End Date:
                        </label>
                        <div className="col-md-7">
                            <input
                                value={
                                    setDetails.endDate &&
                                    reciveDateTimeFormate(setDetails.endDate)[0]
                                }
                                name="endDate"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                required
                                type="date"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mt-2 col-md-6 align-items-baseline">
                        <label className="form-label col-md-4  col-4" htmlFor="select-status">
                            Type :
                        </label>
                        <div className="col-md-7">
                            <select
                                name="type"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                className="form-select"
                                value={setDetails.type}
                                id="select-status"
                            >
                                {selectOptions.map((op) => (
                                    <option value={op.value} key={op.value}>
                                        {op.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        <CustomCheckbox
                            className="custom-check-1"
                            checked={setDetails.isStatic}
                            onChecked={(e, value) => {
                                setSetDetails((prev) => ({
                                    ...prev,
                                    isStatic: value,
                                }));
                            }}
                            name="availableToSales"
                            label="Is Static:"
                        />
                    </div>
                    <div className="d-flex gap-4 justify-content-end">
                        <SpecialButton variant="btn-15" onClick={submitSet} text="Save" />
                        <SpecialButton variant="btn-5" text="Cancel" />
                    </div>
                </div>
            </div>
            <hr />
            {data && <SetNodes nodes={data?.nodes || []} setId={data.id} />}
        </div>
    );
};

export default CreateSet;
