import { lazy, memo, Suspense, startTransition, useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import enrollmentMentorProcessesServices from '../../services/enrollment-mentor-processes-services';
import { useNavigate, useParams } from 'react-router-dom';
import {
    EnrollmentMentorProcess,
    hardwaresInterface,
    hardwearNameInterface,
    userEnrollmentsInterface,
    userEnrollmentsServices,
    userInterface,
} from '../../types/ApiTypes';
// import EnrollmentDetails from './EnrollmentDetails';
import Loader from '../loader/loader';
import { useAppSelector } from '../../Redux/hooks';

const OnBoarding = lazy(() => import('./Onboarding'));
const StudentDetails = lazy(() => import('./StudentDetails'));
const Mentorship = lazy(() => import('./Mentorship'));
const Insights = lazy(() => import('./Insights'));

interface StudentData {
    studentId: number | null;
    username: string | null;
}

const StudentStatistics = () => {
    const [activeKey, setActiveKey] = useState('onboarding');
    const [studentOnboarding, setStudentOnboarding] = useState<EnrollmentMentorProcess[]>([]);
    const [enrollmentService, setEnrollmentService] = useState<userEnrollmentsServices[]>([]);
    const [userEnrollments, setUserEnrollments] = useState<userEnrollmentsInterface[]>([]);
    const [hardwears, setHardwears] = useState<(hardwaresInterface & hardwearNameInterface)[]>([]);
    const [salesPerson, setSalesPerson] = useState<userInterface | null>(null);
    const [courseId, setCourseId] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [studentData, setStudentData] = useState<StudentData>({
        studentId: null,
        username: null
    });
    const { enrollmentId } = useParams();
    const navigate = useNavigate();
    const FRONTEND_ROUTES = useAppSelector(state => state.NavBarReducer.frontendRoutes);

    const handleTabSelect = (key: string | null) => {
        if (key) {
            startTransition(() => {
                setActiveKey(key);
            });
            navigate(FRONTEND_ROUTES.STUDENT.ENROLLED_STUDENTS + `/${enrollmentId}`, {
                state: studentData,
                replace: true,
            });
        }
    };

    async function getOnboarding() {
        const mentorshipProcesses =
            await enrollmentMentorProcessesServices.getEnrollmentInformation(`${enrollmentId}`);
        const enrollmentCourseProcess = mentorshipProcesses?.data?.enrollmentCourseProcess;
        const alluserEnrollments = mentorshipProcesses?.data?.user?.userEnrollments;
        const userEnrollmentsServices = mentorshipProcesses?.data?.enrollmentService;
        const hardwears = mentorshipProcesses?.data?.hardware;
        const salesPerson = mentorshipProcesses?.data?.staff;
        // navigate(FRONTEND_URLS.STUDENT.ENROLLED_STUDENTS + `/${enrollmentId}`, {
        //     state: {
        //         studentId: mentorshipProcesses.data?.userId,
        //         username: mentorshipProcesses.data?.user?.username,
        //     },
        //     replace : true,
        // });
        setStudentData({
            studentId: mentorshipProcesses.data?.userId,
            username: mentorshipProcesses.data?.user?.username,
        });
        setStudentOnboarding(enrollmentCourseProcess);
        setUserEnrollments(alluserEnrollments);
        setEnrollmentService(userEnrollmentsServices);
        setHardwears(hardwears);
        setSalesPerson(salesPerson);
        setCourseId(mentorshipProcesses?.data?.courseId);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        getOnboarding();
    }, [enrollmentId]);

    return (
        <div className="dashboard-page">
            {loading ? (
                <Loader />
            ) : (
                <div className="container">
                    <Tabs
                        activeKey={activeKey}
                        onSelect={handleTabSelect}
                        id="student-statistics-tab"
                        className="my-3"
                    >
                        <Tab eventKey="onboarding" title="Onboarding">
                            <Suspense fallback={<div>Loading Onboarding...</div>}>
                                <OnBoarding studentOnboardingData={studentOnboarding} />
                            </Suspense>
                        </Tab>
                        <Tab eventKey="studentDetails" title="Student Details">
                            <Suspense fallback={<div>Loading Student Details...</div>}>
                                <StudentDetails
                                    courseId={courseId}
                                    userEnrollments={userEnrollments}
                                    enrollmentService={enrollmentService}
                                    hardwares={hardwears}
                                    salesPerson={salesPerson}
                                />
                            </Suspense>
                        </Tab>
                        <Tab eventKey="mentorship" title="Mentorship">
                            <Suspense fallback={<div>Loading Mentorship...</div>}>
                                <Mentorship />
                            </Suspense>
                        </Tab>
                        <Tab eventKey="insights" title="Insights">
                            <Suspense fallback={<div>Loading Insights...</div>}>
                                <Insights />
                            </Suspense>
                        </Tab>
                    </Tabs>
                </div>
            )}
        </div>
    );
};

export default memo(StudentStatistics);
