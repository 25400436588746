import { Alert } from "react-bootstrap";

const PermissionDenied = ({ message = 'You do not have the necessary permissions to access this page. Please contact your administrator if you believe this is an error.' }: { message?: string }) => {
    return (
        <Alert variant="danger" dismissible>
            <Alert.Heading>Permission Denied!</Alert.Heading>
            <p>{message}</p>
        </Alert>
    )
}

export default PermissionDenied;