import { NavBarConstants } from './nav-bar-constants';

export interface InitialStateType {
    isNavbar: boolean;
    permissions: string[];
    frontendRoutes: any;
}

const initialState: InitialStateType = {
    isNavbar: false,
    permissions: [],
    frontendRoutes: [],
};

interface ActionInterface {
    type: any;
    payload: any;
}

const NavBarReducer = (state: InitialStateType = initialState, action: ActionInterface) => {
    switch (action.type) {
        case NavBarConstants.IS_NAVBAR:
            return {
                ...state,
                isNavbar: action.payload,
            };

        case NavBarConstants.PERMISSIONS:
            return {
                ...state,
                permissions: action.payload as string[],
            };

        case NavBarConstants.FRONTEND_ROUTES:
            return {
                ...state,
                frontendRoutes: action.payload,
            };

        default:
            return state;
    }
};

export default NavBarReducer;