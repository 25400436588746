export const PERMISSIONS_LIST = {
    // USER_MANAGEMENT: {
    VIEW_USERS: 'view-users',
    CREATE_USER: 'create-user',
    EDIT_USER: 'edit-user',
    ASSIGN_ROLE_PERMISSION: "can-assign-role-permission",
    DELETE_STUDENT: 'delete-student',
    DELETE_STAFF: 'delete-staff',
    CREATE_STAFF: 'create-staff',
    ASSIGN_ROLE: 'assign-role',
    ASSIGN_LOW_LEVEL_PERMISSION: 'assign-low-level-permission',
    ASSIGN_ALL_PERMISSION: 'assign-all-permission',
    ASSIGN_PERMISSION: 'assign-permission',
    // },
    // INTERNAL_USERS: {
    INTERNAL_USERS: 'internal-users',
    INTERNAL_USERS_ADMIN: 'internal-users-admin',
    // },
    // COURSE_MANAGEMENT: {
    COURSE_MANAGER: 'course-manager',
    CREATE_COURSE: 'access-course',
    EDIT_COURSE: 'create-course',
    COURSE_ADD_ON_STORE: 'course-add-on-store',
    CREATE_SET: 'access-set',
    SEND_NOTIFICATION: 'send-notification',
    // },
    // DOUBT_MANAGEMENT: {
    DOUBT_ADMIN: 'doubt-admin',
    DOUBT_FACULTY: 'doubt-faculty',
    // },
    // SERVICE_MANAGEMENT: {
    CREATE_SERVICES: 'create-services',
    VIEW_SERVICES: 'view-services',
    CREATE_PROCESSES: 'create-processes',
    VIEW_PROCESSES: 'view-processes',
    // },
    // CONTENT_MANAGEMENT: {
    CONTENT_MANAGER: 'content-manager',
    CREATE_NODE: 'access-node',
    VIEW_NODE: 'view-node',
    CREATE_SESSION: 'create-session',
    VIEW_SESSION: 'view-session',
    CREATE_QUIZ: 'access-quiz',
    VIEW_QUIZ: 'view-quiz',
    ADD_CONTENT: 'add-content',
    VIEW_CONTENT: 'view-content',
    CREATE_CONTENT_GROUP: 'create-content-group',
    CONTENT_GROUP: 'content-group',
    // },
    // HARDWARE_MANAGEMENT: {
    CREATE_HARDWARES: 'create-hardwares',
    VIEW_HARDWARES: 'view-hardwares',
    // },
    // DEMO_COURSE_MANAGEMENT: {
    CREATE_DEMO_COURSES: 'create-demo-courses',
    VIEW_DEMO_COURSES: 'view-demo-courses',
    // },
    // ORDER_MANAGEMENT: {
    CREATE_ORDER: 'create-order',
    ORDER_FORM_LCT: 'order-form-lct',
    ORDER_FORM_ADMIN: 'order-form-admin',
    ORDER_FORM_VIEW_ALL: 'order-form-view-all',
    ORDER_FORM_VIEW_SELF: 'order-form-view-self',
    EDIT_PAYMENT: 'edit-payment',
    CAN_MAKE_ZERO_AMOUNT_ORDER: 'can-make-zero-amount-order-form',
    // },
    // ROLE_AND_PERMISSION_MANAGEMENT: {
    CREATE_ROLE: 'create-role',
    CREATE_PERMISSION: 'create-permission',
    // },
    // SUBJECT_MANAGEMENT: {
    CREATE_SUBJECT: 'create-subject',
    VIEW_SUBJECT: 'access-subject',
    CREATE_CHAPTER: 'create-chapter',
    VIEW_CHAPTER: 'access-chapter',
    CREATE_SUBJECT_GROUP: 'create-subject-group',
    SUBJECT_GROUP: 'subject-group',
    // },
    // EXAM_MANAGEMENT: {
    CREATE_EXAM_TARGET: 'create-exam-target',
    EXAM_TARGET: 'exam-target',
    // },
    // STUDENT_CLASS_MANAGEMENT: {
    CREATE_STUDENT_CLASS: 'create-student-class',
    STUDENT_CLASS: 'student-class',
    // },
    // COLLECTION_MANAGEMENT: {
    CREATE_COLLECTION: 'create-collection',
    VIEW_COLLECTION: 'view-collection',
    // },
    // SETTINGS: {
    ADMIN: 'admin',
    VIEW_SETTINGS: 'view-settings',
    CREATE_SETTINGS: 'create-settings',
    // },
    // ENROLLMENT: {
    ENROLLMENT: 'enrollment',
    ENROLLMENT_ADMIN: 'enrollment-admin',
    // },
    ONBOARDING: "onboarding",
    MENTOR: "mentor",
    MENTOR_ADMIN: "mentor-admin",
    LCT: 'lct',
    LCT_ADMIN: 'lct-admin',
    LIVE_DASHBOARD: 'live-dashboard'
};
