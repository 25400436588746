import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { MEDIA_BASE_URL } from "../config";


const workerUrl =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js";

const defaultScale = 1;
type PDFViewerProps = {
  pdf_password: string;
  encrypted_file_url: string;
  scale?: number;
};

function PDFVIewer({ pdf_password = "", encrypted_file_url = "" }: PDFViewerProps) {
  const url = encrypted_file_url?.startsWith("http")
    ? encrypted_file_url
    : MEDIA_BASE_URL + encrypted_file_url;
  return (
    <div
      style={{
        width: "600px",
        height: "200px",
      }}
    >
      <Worker workerUrl={workerUrl}>
        <Viewer
          fileUrl={url}
          onDocumentAskPassword={(e) => e.verifyPassword(pdf_password)}
          defaultScale={defaultScale}
        />
      </Worker>
    </div>
  );
}

export default PDFVIewer;
