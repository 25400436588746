"use client";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { StudentClassesInterface, TargetInterface } from "../../types/ApiTypes";
import Select, { MultiValue, SingleValue } from 'react-select'
import { FloatingLabel } from "react-bootstrap";
import { CallToAction, CampaignType } from "../../utils/constants";
import { CourseType } from "../../types/course.types";
import CustomUpload from "../ModulePDF/Upload";
import { MEDIA_BASE_URL } from "../../config";
import userService from "../../services/user-service";
import notifications from "../../services/notifications";
import toast from "react-hot-toast";
import { CampaignInterface } from "../../types/notifiation";
import { convertISTtoUTC } from "../../utils/helpers";
import usePermit from "../../hooks/usePermit";
import { PERMISSIONS_LIST } from "../../utils/constants/permission-constant";
import PermissionDenied from "../permission-denied/permission-denied";
import useGetPermission from "../../hooks/useGetPermission";



type studentClassesType = { label: string, value: StudentClassesInterface }
type studentTargetsType = { label: string, value: TargetInterface }
type studentCoursesType = { label: string, value: CourseType }

type campaginType = {
  name: string,
  title: string,
  message: string,
  iconUrl: string,
  mediaUrl: string,
  cta: string,
  ctaParam: string,
  category: string,
  sendAt: string,
}


function SendNotification({
  studentClass,
  courses,
  target,
}: {
  studentClass: StudentClassesInterface[],
  target: TargetInterface[],
  courses: CourseType[],
}
) {

  const [cta, setCTA] = useState<any>();
  const [testToken, setTestToken] = useState<any>();
  const [campaignData, setCampaignData] = useState<campaginType>({
    name: "",
    title: "",
    message: "",
    iconUrl: "",
    mediaUrl: "",
    cta: "",
    ctaParam: "",
    category: "",
    sendAt: "",
  });
  const [campaignFilters, setCampaignFilters] = useState<any>({
    studentClass: [],
    studentTargetExam: [],
    appInstallDate: {},
  });

  const [studentClasses, setStudentClasses] = useState<studentClassesType[]>([])
  const [studentTargets, setStudentTargets] = useState<studentTargetsType[]>([])
  const [studentCourses, setStudentCourses] = useState<studentCoursesType[]>([])
  const [allCampaigns, setAllCampaigns] = useState<CampaignInterface[]>([])

  const [studentCount, setStudentCount] = useState<number>(0)
  const [templateId, setTemplateId] = useState<number>()
  const [campaignId, setCampaignId] = useState<number>()

  const [testNotificaionNumber, setTestNotificaionNumber] = useState('')
  const { isHasNotificationPermission } = useGetPermission()


  const fileUpload = (url: any) => {
    setCampaignData(
      (prev) => ({
        ...prev,
        mediaUrl: url,
        iconUrl: url
      })
    )
  };

  const studentClassChange = (stCls: MultiValue<studentClassesType>) => {
    const data = stCls.map(
      (st) => st.value.id
    )
    setCampaignFilters(
      (prev: any) => ({
        ...prev,
        studentClass: data
      })
    )
  };

  const studentTargetChange = (stTar: MultiValue<studentTargetsType>) => {
    const data = stTar.map(
      (tar) => tar.value.id
    );

    setCampaignFilters(
      (prev: any) => ({
        ...prev,
        studentTargetExam: data
      })
    )
  };

  const dateChange = (e: any) => {
    const { name, value } = e.target;

    const campaignFiltersData = { ...campaignFilters };

    if (name === "from-date") {
      campaignFiltersData.appInstallDate.from = value;
    }
    if (name === "till-date") {
      campaignFiltersData.appInstallDate.till = value;
    }

    setCampaignFilters(campaignFiltersData);
  };

  const handleCampSelect = (camp: SingleValue<{ label: string, value: any }>, name: string) => {
    if (name === "cta") {
      setCTA(camp?.value);
      setCampaignData(
        (prev: any) => ({
          ...prev,
          cta: camp?.value
        })
      )
    }
    else if (name === 'ctaParam') {
      setCampaignData(
        (prev: any) => ({
          ...prev,
          ctaParam: camp?.value.id
        })
      )
    }
    else if (name === 'category') {
      setCampaignData(
        (prev: any) => ({
          ...prev,
          category: camp?.value
        })
      )
    }
  }

  const campDataChange = (e: any) => {
    const { name, value } = e.target;
    const data = { ...campaignData };
    if (name === 'sendAt') {
      setCampaignData(
        (camp) => ({
          ...camp,
          'sendAt': convertISTtoUTC(value)
        })
      )
    }
    else {
      setCampaignData(
        (camp) => ({
          ...camp,
          [name]: value
        })
      )
    }
  };

  const sendNotificationData = async () => {
    const { name, sendAt } = campaignData

    if (!templateId) {
      toast.error("make template first.")
      return
    }

    if (!name || !sendAt) {
      toast.error("Selct send time first.")
    }

    try {
      const data = {
        "name": campaignData.name.trim(),
        "scheduledAt": campaignData.sendAt,
        "pushTemplateId": templateId
      }
      const pushNotification = await notifications.createCampaign(data)
      const campaignId = pushNotification.data.campaign.id
      toast.success(pushNotification.message)
      setCampaignId(campaignId)

      const userCampaign = await notifications.userCampaingn({
        campaignId,
        classes: campaignFilters.studentClass,
        targets: campaignFilters.studentTargetExam
      })

      toast.success(userCampaign.message)

    } catch (error) {
      toast.error(JSON.stringify(error))
    }
  };

  const sendTestNotificationData = async () => {
    if (!testNotificaionNumber) {
      toast.error("Enter Test Number.")
      return
    }

    if (!templateId) {
      toast.error("template is not selected.")
      return
    }

    const data = {
      "pushTemplateId": templateId,
      "phoneNumber": testNotificaionNumber.trim()
    }

    try {
      const testNotification = await notifications.testNotification(data)
      if (testNotification.status === 'success') {
        toast.success("Notificaion send successfully.")
      }
      else {
        toast.error("Notificaion not send. Something went wrong." + testNotification.message)
      }
    } catch (error) {
      toast.error(JSON.stringify(error))
    }
  };


  const getTotalStudents = async () => {
    try {
      const userCount = await userService.getUsersCount({ classes: campaignFilters.studentClass, targets: campaignFilters.studentTargetExam })
      setStudentCount(userCount.data.count)
    }
    catch (err) {
      console.log('err ===>', err)
    }
  };

  async function makeTemplate() {
    const { name, title, message, mediaUrl, cta, ctaParam } = campaignData;

    if (!name || !title || !message || !mediaUrl || !cta) {
      toast.error("Please fill out all required fields in the campaign data.");
      return
    }

    if ((cta === "EXTERNAL") && !ctaParam && (typeof ctaParam === 'string')) {
      toast.error("Please give link.")
      return
    }

    if ((cta === "SINGLECOURSE") && !ctaParam && (typeof ctaParam === 'number')) {
      toast.error('Please choose course.')
      return
    }

    try {
      const data = {
        "name": name.trim(),
        "title": title.trim(),
        "body": message.trim(),
        "imageUrl": mediaUrl,
        "cta": cta,
        "ctaParams": (cta === "EXTERNAL") ? { "link": ctaParam } : (cta === "SINGLECOURSE") ? { "courseId": +ctaParam } : {}
      }
      const template = await notifications.makeNotificationTemplate(data)
      setTemplateId(template.data.template.id)
      toast.success("templaate created!")
    } catch (error) {
      toast.error(JSON.stringify(error))
    }
  }

  async function getAllCampaings() {
    try {
      const campaigns = await notifications.getAllCampaigns()
      setAllCampaigns(campaigns.data.campaigns)
    } catch (error) {
      toast.error(JSON.stringify(error))
    }
  }

  useEffect(() => {
    if (studentClass && courses && target) getTotalStudents();

    setStudentClasses(
      studentClass.map(
        (stCls) => ({
          label: stCls.name,
          value: stCls
        })
      )
    )

    setStudentTargets(
      target.map(
        (t) => ({
          label: t.name,
          value: t
        })
      )
    )

    setStudentCourses(
      courses.map(
        (course) => ({
          label: course.name,
          value: course
        })
      )
    )

    getAllCampaings()
  }, []);

  if (!isHasNotificationPermission) {
    return <PermissionDenied />
  }

  return (
    <div className="dashboard-page">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3>Send Push Notifications</h3>
          </div>
        </div>

        {/* select students */}
        <div className="espanel mb-4">
          <div className="row">
            <h5>Students</h5>

            <div className="col-md-12">
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Select
                      className="react-select-container"
                      options={studentClasses}
                      isMulti
                      closeMenuOnSelect={false}
                      placeholder='Select Student Class'
                      onChange={(e) => {
                        studentClassChange(e)
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Select
                      className="react-select-container"
                      options={studentTargets}
                      isMulti
                      closeMenuOnSelect={false}
                      placeholder='Select Student Target'
                      onChange={(e) => {
                        studentTargetChange(e)
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="col-md-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>App install Date</Form.Label>
                <Row>
                  <Col>
                    <FloatingLabel
                      controlId="From"
                      label="From"
                      className="mb-3"
                    >
                      <Form.Control
                        type="date"
                        name="from-date"
                        placeholder="From"
                        onChange={(e) => dateChange(e)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel
                      controlId="From"
                      label="Till"
                      className="mb-3"
                    >
                      <Form.Control
                        type="date"
                        name="till-date"
                        placeholder="Last name"
                        onChange={(e) => dateChange(e)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Button onClick={getTotalStudents}>Get Total Students</Button>
              <strong>
                {studentCount} Students may receive this notifications
              </strong>
            </div>
          </div>
        </div>

        {/* Create Campaign */}
        <div className="espanel mb-4">
          <div className="row">
            <h5>Campaign</h5>
            <div className="col-md-12">
              <Tabs
                defaultActiveKey="new"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="new" title="New Campaign">
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="Name"
                          label="Name"
                          className="mb-3"
                        >
                          <Form.Control
                            name="name"
                            onChange={(e) => campDataChange(e)}
                            type="text"
                            placeholder=""
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="Title"
                          label="Title"
                          className="mb-3"
                        >
                          <Form.Control
                            name="title"
                            onChange={(e) => campDataChange(e)}
                            type="text"
                            placeholder=""
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="Message"
                          label="Message"
                          className="mb-3"
                        >
                          <Form.Control
                            name="message"
                            onChange={(e) => campDataChange(e)}
                            type="text"
                            placeholder=""
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Row>
                        <Row>
                          <Col className="my-2">
                            <Select
                              className="react-select-container"
                              options={CallToAction}
                              placeholder='Call to action'
                              name='cta'
                              onChange={(e) => handleCampSelect(e, 'cta')}
                            />
                          </Col>
                          {/* </Row> */}

                          {/* <Col className="my-2">
                            <Form.Group>
                              <Select
                                className="react-select-container"
                                options={CampaignType}
                                name="category"
                                placeholder='Campaign Type'
                                onChange={(e) => handleCampSelect(e, 'category')}
                              />
                            </Form.Group>
                          </Col> */}

                          {/* <Row> */}
                          <Col className="my-2">
                            {cta && cta === "SINGLECOURSE" && (
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Select
                                  className="react-select-container"
                                  options={studentCourses}
                                  placeholder='Select Course'
                                  name='ctaParam'
                                  onChange={(e) => {
                                    handleCampSelect(e, 'ctaParam')
                                  }}
                                />
                              </Form.Group>
                            )}

                            {cta && cta === "EXTERNAL" && (
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <FloatingLabel
                                  controlId="URL"
                                  label="URL"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    name="ctaParam"
                                    onChange={(e) => campDataChange(e)}
                                    type="text"
                                    placeholder=""
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            )}
                          </Col>

                        </Row>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <CustomUpload
                          url={MEDIA_BASE_URL + campaignData.mediaUrl}
                          onUploaded={(url) => fileUpload(url)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button onClick={makeTemplate}>Make Template</Button>

                </Tab>
                <Tab eventKey="existing" title="Existing">
                  <CampaignList campaigns={allCampaigns} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>

        {/* Choose send attributes */}
        <div className="espanel mb-4">
          <h5>Send</h5>
          <div className="row">
            <div className="col-md-12">
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <FloatingLabel
                      controlId="EnterTokenToTestNotification"
                      label="Enter user Mobile Number to Test Notification"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        onChange={(e) => setTestNotificaionNumber(e.target.value)}
                        placeholder=""
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Button onClick={sendTestNotificationData}>Send Test</Button>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <FloatingLabel
                      controlId="SendAt"
                      label="Send at"
                      className="mb-3"
                    >
                      <Form.Control
                        name="sendAt"
                        onChange={(e) => campDataChange(e)}
                        type="datetime-local"
                        placeholder=""
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Button onClick={sendNotificationData}>Send</Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendNotification;




const CampaignCard = ({ campaign }: { campaign: CampaignInterface }) => (
  <div style={cardStyle}>
    <h3 style={nameStyle}>{campaign.name}</h3>
    <p><strong>Status:</strong> {campaign.status}</p>
    <p><strong>Scheduled At:</strong> {new Date(campaign.scheduledAt).toLocaleDateString()}</p>
    <p><strong>Push Template ID:</strong> {campaign.pushTemplateId}</p>
    <p><strong>Created On:</strong> {new Date(campaign.createdOn).toLocaleDateString()}</p>
  </div>
);

const CampaignList = ({ campaigns }: { campaigns: CampaignInterface[] }) => (
  <div style={gridStyle}>
    {campaigns.map((campaign, index) => (
      <CampaignCard key={campaign.id} campaign={campaign} />
    ))}
  </div>
);

// Styles
const gridStyle: React.CSSProperties = {
  display: 'grid',
  gap: '1rem',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
};

const cardStyle: React.CSSProperties = {
  border: '1px solid #ccc',
  padding: '1rem',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
};

const nameStyle: React.CSSProperties = {
  color: '#333',
  marginBottom: '0.5rem',
};
