import { TableColumn } from 'react-data-table-component';
import { EnrolledStudent } from '../../types/enrolled-student';
import { FRONTEND_URLS } from '../../config';
import toast from 'react-hot-toast';
import { FaRegCopy } from 'react-icons/fa';
import { useState } from 'react';

const getNextCall = (e: Date) => {
    const lastCallDate = new Date(e).setHours(0, 0, 0, 0)
    return new Date(new Date(lastCallDate).getTime() + 12 * 24 * 60 * 60 * 1000)
};

const today = new Date();
today.setHours(0, 0, 0, 0);

export const conditionalStyles = [
    {
        when: (row: EnrolledStudent) => row.x_days_usage >= 5 * 60 * 60,
        style: (row: EnrolledStudent) => ({
            backgroundColor:
                !!row.last_mentor_call && getNextCall(row.last_mentor_call) >= today
                    ? '#ffffff'
                    : '#E0F6FF',
            color: '#1e272e',
            transition: 'all 0.2s ease-in-out',
        }),
    },
    {
        when: (row: EnrolledStudent) => row.x_days_usage < 5 * 60 * 60,
        style: (row: EnrolledStudent) => ({
            backgroundColor:
                row.last_mentor_call && getNextCall(row.last_mentor_call) >= today
                    ? '#FFF1D7'
                    : '#FFEAF4',
            color: '#333',
            transition: 'all 0.2s ease-in-out',
        }),
    },
];

const divStyles = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '1px',
};
export const enrollDataColumns = (
    handleSelectRow: (e: React.ChangeEvent<HTMLInputElement>, studentId: number, enrollment_id: number) => void,
    selectedStudents: { [id: number]: boolean }
): TableColumn<EnrolledStudent>[] => [
        {

            cell: (row: EnrolledStudent) => (
                <input
                    type="checkbox"
                    checked={!!selectedStudents[row.enrollment_id]} // Check selection state by `student_id`
                    onChange={(e) => handleSelectRow(e, row.enrollment_id, row.student_id)} // Pass `student_id` to the handler
                />
            ),
        },
        {
            name: 'Enroll Date',
            selector: (row: EnrolledStudent) => row.enrollment_date || '',
            sortFunction: (rowA: EnrolledStudent, rowB: EnrolledStudent) => {
                const dateA = rowA.enrollment_date ? new Date(rowA.enrollment_date).getTime() : 0;
                const dateB = rowB.enrollment_date ? new Date(rowB.enrollment_date).getTime() : 0;
                return dateA - dateB;
            },
            cell: (row: EnrolledStudent) => (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                    style={divStyles}
                    title={
                        row.enrollment_date
                            ? new Date(row.enrollment_date).toLocaleDateString('en-GB')
                            : ''
                    }
                >
                    {row.enrollment_date
                        ? new Date(row.enrollment_date).toLocaleDateString('en-GB')
                        : ''}
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row: EnrolledStudent) => row.name || '',
            cell: (row: EnrolledStudent) => {
                const phone = row.username || '';
                const maskedPhone =
                    phone.length === 12 ? `${phone.slice(2, 4)}******${phone.slice(-4)}` : phone;
                return (
                    <div
                        data-tag="allowRowEvents"
                        className='text-breaker'
                        style={{ ...divStyles, whiteSpace: 'normal', wordBreak: 'break-word' }}
                        title={row.name || ''}
                    >
                        {row.name || ''}
                        <div data-tag="allowRowEvents" style={divStyles} title={phone}>
                            {maskedPhone}
                        </div>
                    </div>
                )
            },
            sortable: true,
        },
        {
            name: 'email',
            cell: (row: EnrolledStudent) => {
                return (
                    <div data-tag="allowRowEvents" className='text-breaker' style={{ ...divStyles }}>
                        {row.primary_email}
                    </div>
                );
            },
            sortable: true,
        },
        {
            name: 'Course',
            selector: (row: EnrolledStudent) => row.course_name,
            cell: (row: EnrolledStudent) => (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                    style={{ ...divStyles, whiteSpace: 'normal', wordBreak: 'break-word' }}
                    title={row.course_name || ''}
                >
                    {row.course_name || ''}
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Validity',
            sortFunction: (rowA: EnrolledStudent, rowB: EnrolledStudent) => {
                const dateA = rowA.validity ? new Date(rowA.validity).getTime() : 0;
                const dateB = rowB.validity ? new Date(rowB.validity).getTime() : 0;
                return dateA - dateB;
            },
            cell: (row: EnrolledStudent) => (
                <div
                    data-tag="allowRowEvents"
                    style={divStyles}
                    className='text-breaker'
                    title={row.validity ? new Date(row.validity).toLocaleDateString('en-GB') : ''}
                >
                    { }
                    {row.validity ? new Date(row.validity).toLocaleDateString('en-GB') : ''}
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Mentor',
            selector: (row: EnrolledStudent) => row.mentor_name || '',
            cell: (row: EnrolledStudent) => (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                    style={{ ...divStyles, whiteSpace: 'normal', wordBreak: 'break-word' }}
                    title={row.mentor_name || ''}
                >
                    {row.mentor_name || ''}
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Group',
            selector: (row: EnrolledStudent) => row.group || '',
            cell: (row: EnrolledStudent) => (
                <div data-tag="allowRowEvents" className='text-breaker' style={divStyles} title={row.group || ''}>
                    {row.group || ''}
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Last Mentor Call',
            selector: (row: EnrolledStudent) =>
                row.last_mentor_call ? new Date(row.last_mentor_call).getTime() : 0,
            sortFunction: (rowA: EnrolledStudent, rowB: EnrolledStudent) => {
                const dateA = rowA.last_mentor_call ? new Date(rowA.last_mentor_call).getTime() : 0;
                const dateB = rowB.last_mentor_call ? new Date(rowB.last_mentor_call).getTime() : 0;
                return dateA - dateB;
            },
            cell: (row: EnrolledStudent) => (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                    style={divStyles}
                    title={
                        row.last_mentor_call
                            ? new Date(row.last_mentor_call).toLocaleDateString('en-GB')
                            : ''
                    }
                >
                    {row.last_mentor_call
                        ? new Date(row.last_mentor_call).toLocaleDateString('en-GB').split('/').slice(0, 2).join('/')
                        : ''}
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Next Call',
            selector: (row: EnrolledStudent) =>
                row.last_mentor_call
                    ? getNextCall(row.last_mentor_call).getTime()
                    : 0, // Use timestamp for sorting
            sortFunction: (rowA: EnrolledStudent, rowB: EnrolledStudent) => {
                const nextCallA = rowA.last_mentor_call
                    ? getNextCall(rowA.last_mentor_call).getTime()
                    : 0;
                const nextCallB = rowB.last_mentor_call
                    ? getNextCall(rowB.last_mentor_call).getTime()
                    : 0;
                return nextCallA - nextCallB;
            },
            cell: (row: EnrolledStudent) => (
                <div
                    data-tag="allowRowEvents"
                    className='text-breaker'
                    style={divStyles}
                    title={
                        row.last_mentor_call
                            ? getNextCall(row.last_mentor_call).toLocaleDateString('en-GB')
                            : ''
                    }
                >
                    <span
                        style={{
                            color:
                                row.last_mentor_call &&
                                    getNextCall(row.last_mentor_call) < today
                                    ? 'red'
                                    : 'green',
                        }}
                    >
                        {row.last_mentor_call
                            ? getNextCall(row.last_mentor_call).toLocaleDateString('en-GB').split('/').slice(0, 2).join('/')
                            : ''}
                    </span>
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Attendance',
            cell: (row: EnrolledStudent) => (
                <div data-tag="allowRowEvents" style={divStyles}>
                    <span
                        className='text-breaker'
                        style={{
                            color: row.x_days_usage >= 5 * 60 * 60 ? 'green' : '#E23F44',
                            fontWeight: 'bold',
                        }}
                    >
                        {row.x_days_usage < 5 * 60 * 60 ? 'POOR' : 'GOOD'}
                    </span>
                </div>
            ),
            sortable: true,
            selector: (row: EnrolledStudent) => row.x_days_usage,
            // sortFunction: (rowA: EnrolledStudent, rowB: EnrolledStudent) =>
            //     rowA.x_days_usage - rowB.x_days_usage,
        },
        {
            name: "Copy Insight Url",
            cell: (row: EnrolledStudent) => {

                const handleCopyClick = () => {
                    const insightUrl = `${window.location.origin}${FRONTEND_URLS.STUDENT.STUDENT_PARENT_REPORT}/${row.student_id}`;
                    navigator.clipboard.writeText(insightUrl)
                        .then(() => {
                            toast.success("Link copied to clipboard!");
                        })
                        .catch((error) => {
                            console.error("Failed to copy: ", error);
                        });
                };

                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                            onClick={handleCopyClick}
                            style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <FaRegCopy
                                size={18}
                                style={{
                                    transition: "transform 0.3s ease, color 0.3s ease",
                                }}
                            />
                        </button>
                    </div>
                );
            }
        }
    ];
