import React, { useRef, useState } from 'react';
import { Button, FloatingLabel, Form, ProgressBar } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import { MEDIA_BASE_URL } from '../../config';
import QuizService from '../../services/ApiServices/QuizService';
import {
    FilteredQuestionData,
    QuizPayload,
    quizQuestionType,
    QuizType,
} from '../../types/quizTypes';
import {
    GroupedQuestionData,
    QuestionData,
    QuizImportInterface,
    quizResponseInterface,
} from '../../types/types';
import { pdfUploadHelper } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import SimilarQuestionQuizModal from './Modals/SimilarQuestionQuizModal';
import KatexRenderer from '../Katex/KatexRenderer';

type ProgressData = { percentage: number; msg: string };
type ImgMapping = { [key: string]: string };

function UploadQuizPage() {
    const [quizType, setQuizType] = useState<string>('QUIZ');
    const [loading, setLoading] = useState<boolean>(false);
    const [filteredQuestionsData, setFilteredQuestionsData] = useState<Array<FilteredQuestionData>>(
        []
    );
    const [showModal, setShowModal] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [storedFiles, setStoredFiles] = useState<FileList | []>([]);
    const [progress, setProgress] = useState<ProgressData>({ percentage: 0, msg: '' });
    const [uniqueQuestions, setUniqueQuestions] = useState<any>([]);
    const [selectedQuestionData, setSelectedQuestionData] = useState<FilteredQuestionData>({
        question: [],
        results: [],
        selectedQue: null,
    });
    const [quizDetails, setQuizDetails] = useState<QuizPayload>({
        quizType: QuizType.QUIZ,
        internalName: '',
        showInstructions: quizType == 'QUIZ' ? true : false,
        instructions: '',
        timeLimit: quizType == 'QUIZ' ? 60 : 0,
        minTimeSubmission: quizType == 'QUIZ' ? 10 : 0,
        totalAttempts: 0,
        showSolutions: true,
        showRank: true,
        showReport: true,
        language: 'ENGLISH',
        postSubmissionMessage: '',
        sections: [],
    });
    const [imgMappings, setImgMappings] = useState<ImgMapping>({});

    const handleCreateQuiz = async () => {
        try {
            // toast.loading('Creating quiz...');
            const quizServiceResponse = await QuizService.createQuiz({
                ...quizDetails,
                timeLimit: Number(quizDetails.timeLimit) * 60,
                minTimeSubmission: Number(quizDetails.minTimeSubmission) * 60,
                sections: quizDetails.sections.length
                    ? quizDetails.sections
                    : [
                          {
                              sectionName: 'section',
                              maxQuestions: 0,
                          },
                      ],
            });
            const quizData = quizServiceResponse.data;
            toast.success('Quiz Created Successfully. Quiz Id: ' + quizData.id);
            return quizData;
        } catch (error) {
            toast.error(JSON.stringify(error));
        }
    };

    const replaceImgUrlInQues = (filteredQuestions: FilteredQuestionData[], files: File[]) => {
        let filteredJson = JSON.stringify(filteredQuestions);
        const mappings: any = {};

        if (filteredJson) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const imgUrl = URL.createObjectURL(file);
                mappings['images/' + file.name] = imgUrl;
                filteredJson = filteredJson.replace('images/' + file.name, imgUrl);
            }
            setImgMappings(mappings);
        }
        return JSON.parse(filteredJson);
    };

    const handleUploadImgs = async (questionsData: any) => {
        const allFiles = Array.from(storedFiles || []);
        const onlyImgs = allFiles.filter((file) => file.name !== 'assessmentData.json');
        const uploadedFiles: { key: string; fileName: string }[] = [];

        for (let file of onlyImgs) {
            try {
                const key = await pdfUploadHelper(file);
                uploadedFiles.push({ key, fileName: file.name });
            } catch (err) {
                toast.error('Failed to upload file: ' + file.name);
            }
        }

        let JsonData = JSON.stringify(questionsData);

        if (JsonData) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                const uploadedFile = uploadedFiles[i];
                if (imgMappings) {
                    JsonData = JsonData?.replace(
                        imgMappings['images/' + uploadedFile.fileName],
                        MEDIA_BASE_URL + uploadedFile.key
                    );
                }
            }
            return JSON.parse(JsonData);
        } else {
            toast.error('Data not found.');
        }
    };

    const readFileAndProcessAssesmentJson = async (file: any) => {
        try {
            const jsonContent = await readFileContent(file);
            const data: QuizImportInterface = JSON.parse(jsonContent);
            const quizData = {
                quizType: quizType as QuizType,
                internalName: data['spayee:resource']['spayee:title'],
                showInstructions:
                    data['spayee:resource']['spayee:instruction'].length == 0 ? false : true,
                instructions: data['spayee:resource']['spayee:instruction'],
                timeLimit: quizType === 'QUIZ' ? quizDetails.timeLimit : 0,
                minTimeSubmission: quizType === 'QUIZ' ? quizDetails.minTimeSubmission : 0,
                totalAttempts: quizType === 'QUIZ' ? 0 : 0,
                showSolutions: true,
                showRank: true,
                showReport: true,
                language: 'ENGLISH',
                postSubmissionMessage: '',
                sections: data['spayee:resource']['spayee:SectionGrouping'].map(
                    (section, index) => ({
                        id: index,
                        sectionName: section.sectionName,
                        maxQuestions: section.maxQuestions,
                    })
                ),
            };

            setQuizDetails(quizData);
            // setJsondata(data);
            return data;
        } catch (error) {
            console.error(error);
        }
    };

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        setLoading(true);
        setStoredFiles(e.target.files || []);

        try {
            const jsonFile = files?.find((file) => file.name === 'assessmentData.json');
            if (jsonFile) {
                const data = await readFileAndProcessAssesmentJson(jsonFile);
                const questionData = await generateQuestionData(data);
                if (questionData) {
                    const filteredQuestions = await checkSimilarQuestions(questionData);
                    if (filteredQuestions.length === 0) {
                        setUniqueQuestions(questionData);
                        toast.success('did not found any duplicate questions');
                    } else {
                        const filteredQuestionsWithImgUrl = replaceImgUrlInQues(
                            filteredQuestions,
                            files
                        );
                        setFilteredQuestionsData(filteredQuestionsWithImgUrl);
                    }
                }
            }
        } catch (error) {
            toast.error('Failed to read JSON file');
        } finally {
            setLoading(false);
        }
    };

    const readFileContent = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;

            reader.readAsText(file);
        });
    };

    function transformData(inputArray: (string | number)[] | undefined) {
        if (!inputArray) {
            alert('data not found.');
            return;
        }
        const resultMap = new Map();

        inputArray.forEach((item, optionIndex) => {
            // Use regex to split the first part as index and the rest as matches
            //@ts-ignore
            const [index, matchesPart] = item.match(/^(\d+|[A-Za-z]+)(.*)$/).slice(1, 3);
            const matches = matchesPart
                .split(',')
                .map((match: any) => match.trim())
                .filter(Boolean);

            // Update resultMap for each match option
            matches.forEach((matchOption: any) => {
                if (!resultMap.has(matchOption)) {
                    resultMap.set(matchOption, {
                        option: matchOption,
                        matchOptionIndexes: [],
                    });
                }
                resultMap.get(matchOption).matchOptionIndexes.push(optionIndex);
            });
        });

        // Convert the resultMap values to an array
        return Array.from(resultMap.values());
    }

    const generateQuestionData = async (data: any) => {
        try {
            // const jdata = data ? data : jsondata;
            const questionData: any[] = [];
            const questions = data?.['spayee:resource']['spayee:questionIds'] ?? {};
            const paragraphs: { id: number; paragraph: string }[] = [];
            const questionDataKeys: string[] = Object.keys(questions);

            for (let index = 0; index < questionDataKeys.length; index++) {
                const spyaeeQuestion = questions[questionDataKeys[index]];
                if ('questiongroup' in questions[questionDataKeys[index]].data) {
                    const question = spyaeeQuestion as GroupedQuestionData;
                    const paragraphQuestions = question.data.question;
                    const paragraph = question.data.grouptext;

                    let paragraphId: number = -1;

                    if (paragraphQuestions.length > 0) {
                        paragraphId =
                            paragraphs.find((para) => para.paragraph === paragraph)?.id || -1;
                    }
                    if (paragraphId === -1) {
                        const response = await QuizService.saveParagraphs(paragraph);
                        paragraphs.push(response.data);
                        paragraphId = response.data.id;
                    }

                    for (let index = 0; index < paragraphQuestions.length; index++) {
                        const paragraphQuestion = paragraphQuestions[index];
                        const questionSolution = question.data.answer.find(
                            (ans) => ans.questionId === paragraphQuestion.id
                        );

                        const questionSpayeeType = paragraphQuestion.type;
                        const qType: quizQuestionType =
                            questionSpayeeType === 'objective'
                                ? 'SINGLE_CORRECT'
                                : questionSpayeeType === 'multichoice'
                                ? 'MULTIPLE_CORRECT'
                                : questionSpayeeType === 'numerical'
                                ? 'NUMERICAL'
                                : questionSpayeeType === 'match'
                                ? 'MATCH_THE_COLUMN'
                                : 'SUBJECTIVE';

                        const qTypeLabel =
                            qType === 'SINGLE_CORRECT'
                                ? 'SINGLE CORRECT TYPE'
                                : qType === 'MULTIPLE_CORRECT'
                                ? 'ONE OR MORE THAN ONE CORRECT TYPE'
                                : qType === 'NUMERICAL'
                                ? 'NUMERICAL TYPE'
                                : qType === 'SUBJECTIVE'
                                ? 'SUBJECTIVE TYPE'
                                : qType === 'MATCH_THE_COLUMN'
                                ? 'MATCH THE COLUMN TYPE'
                                : '';

                        const questionFormatedData = {
                            paragraphId,
                            qOrder: question.sequence,
                            qType,
                            qTypeLabel,
                            language: 'English',
                            sectionId: question.section,
                            qInstructions: undefined,
                            qText: paragraphQuestion.text,
                            options: [
                                'SINGLE_CORRECT',
                                'MULTIPLE_CORRECT',
                                'MATCH_THE_COLUMN',
                            ].includes(qType)
                                ? paragraphQuestion.options.option.map((opt) => ({
                                      qOption: opt.content,
                                      optionOrder: opt.id,
                                      isSolution: questionSolution?.correctOptions.option.includes(
                                          opt.id
                                      ),
                                  }))
                                : [],
                            qHint: '',
                            qSolution: questionSolution?.solution.text
                                ? questionSolution?.solution.text
                                : '<div></div>',
                            positiveMarks: +paragraphQuestion.mark,
                            negativeMarks: +paragraphQuestion.penalty,
                            partialMarks: 0,
                            correctAnswer:
                                qType === 'MATCH_THE_COLUMN'
                                    ? undefined
                                    : qType === 'NUMERICAL'
                                    ? questionSolution?.correctOptions.option
                                        ? +questionSolution?.correctOptions.option
                                        : undefined
                                    : undefined,
                            rangeFrom: 0,
                            rangeTo: 0,
                            level1Id: null,
                            level2Id: null,
                            matches:
                                qType === 'MATCH_THE_COLUMN'
                                    ? transformData(questionSolution?.correctOptions.option)
                                    : undefined,
                        };
                        questionData.push(questionFormatedData);
                    }
                } else {
                    const question = spyaeeQuestion as QuestionData;
                    const questionSpayeeType = question.data.type;
                    const qType: quizQuestionType =
                        questionSpayeeType === 'objective'
                            ? 'SINGLE_CORRECT'
                            : questionSpayeeType === 'multichoice'
                            ? 'MULTIPLE_CORRECT'
                            : questionSpayeeType === 'numerical'
                            ? 'NUMERICAL'
                            : questionSpayeeType === 'match'
                            ? 'MATCH_THE_COLUMN'
                            : 'SUBJECTIVE';

                    const qTypeLabel =
                        qType === 'SINGLE_CORRECT'
                            ? 'SINGLE CORRECT TYPE'
                            : qType === 'MULTIPLE_CORRECT'
                            ? 'ONE OR MORE THAN ONE CORRECT TYPE'
                            : qType === 'NUMERICAL'
                            ? 'NUMERICAL TYPE'
                            : qType === 'SUBJECTIVE'
                            ? 'SUBJECTIVE TYPE'
                            : qType === 'MATCH_THE_COLUMN'
                            ? 'MATCH THE COLUMN TYPE'
                            : '';

                    const questionFormatedData = {
                        paragraphId: undefined,
                        qOrder: question.sequence,
                        qType,
                        qTypeLabel,
                        language: 'English',
                        sectionId: question.section,
                        qInstructions: undefined,
                        qText: question.data?.text,
                        options: [
                            'SINGLE_CORRECT',
                            'MULTIPLE_CORRECT',
                            'MATCH_THE_COLUMN',
                        ].includes(qType)
                            ? question.data.options.option.map((opt) => ({
                                  qOption: opt.content,
                                  optionOrder: opt.id,
                                  isSolution: question.data.answer.correctOptions.option.includes(
                                      opt.id
                                  ),
                              }))
                            : [],
                        qHint: '',
                        qSolution: question.data.answer.solution?.text
                            ? question.data.answer.solution?.text
                            : '<div></div>',
                        positiveMarks: +question.data.mark,
                        negativeMarks: +question.data.penalty,
                        partialMarks: 0,
                        correctAnswer:
                            qType === 'MATCH_THE_COLUMN'
                                ? undefined
                                : qType === 'NUMERICAL'
                                ? +question.data.answer.correctOptions.option
                                : undefined,
                        rangeFrom: 0,
                        rangeTo: 0,
                        level1Id: null,
                        level2Id: null,
                        matches:
                            qType === 'MATCH_THE_COLUMN'
                                ? transformData(question.data.answer.correctOptions.option)
                                : undefined,
                    };
                    questionData.push(questionFormatedData);
                }
            }
            return questionData || [];
        } catch (error) {
            console.error(error);
        }
    };

    const checkSimilarQuestions = async (questionData: any[]) => {
        const filteredQuestions: Array<FilteredQuestionData> = [];
        const queLen = questionData.length;
        try {
            for (let i = 0; i < queLen; i++) {
                const progressValue = Math.round(((i + 1) / queLen) * 100);
                setProgress({
                    percentage: progressValue,
                    msg: 'Please wait we are searching similar questions',
                });

                const question = questionData[i];
                const response = await QuizService.searchSimilerQuetions(question.qText);

                if (response) {
                    filteredQuestions.push({
                        question,
                        results: response.data || [],
                        selectedQue: null,
                    });
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setProgress({ percentage: 0, msg: '' });
            return filteredQuestions || [];
        }
    };

    const resetStates = () => {
        setQuizDetails({
            quizType: QuizType.QUIZ,
            internalName: '',
            showInstructions: quizType == 'QUIZ' ? true : false,
            instructions: '',
            timeLimit: quizType == 'QUIZ' ? 60 : 0,
            minTimeSubmission: quizType == 'QUIZ' ? 10 : 0,
            totalAttempts: 0,
            showSolutions: true,
            showRank: true,
            showReport: true,
            language: 'ENGLISH',
            postSubmissionMessage: '',
            sections: [],
        });
        setFilteredQuestionsData([]);
        setUniqueQuestions([]);
        setSelectedQuestionData({ question: [], results: [], selectedQue: null });
        setQuizType('QUIZ');
        setStoredFiles([]);
        setImgMappings({});
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const getQuestionsWithSection = (questionData: any[], quizData: quizResponseInterface) => {
        const questionsWithSections = questionData.map((question) => ({
            ...question,
            sectionId:
                quizData.sections.length === 1
                    ? quizData.sections[0].id
                    : quizData.sections.find((section) => {
                          const sectionId = question.sectionId;
                          if (typeof sectionId === 'string') {
                              return section.sectionName === sectionId.split(',')[0];
                          }
                          return false;
                      })?.id,
            matches: [],
        }));

        return questionsWithSections || [];
    };

    const createTest = async () => {
        try {
            setProgress({ percentage: -1, msg: 'Uploading images...' });
            const questionsWithImgUrl = await handleUploadImgs(uniqueQuestions);
            setProgress({ percentage: -1, msg: 'Creating quiz...' });
            const quizData = await handleCreateQuiz();
            setProgress({ percentage: -1, msg: 'Uploading questions...' });
            if (quizData) {
                const questionsWithSections = getQuestionsWithSection(
                    questionsWithImgUrl,
                    quizData
                );

                await Promise.allSettled(
                    questionsWithSections.map((question: any) =>
                        QuizService.addQuestion(quizData.id, question)
                    )
                ).catch((error) => error.forEach((err: any) => console.error(err)));

                resetStates();
                toast.success('Questions created successfully');
            }
        } catch (error: any) {
            toast.error(JSON.stringify(error.message));
        } finally {
            setProgress({ percentage: 0, msg: '' });
        }
    };

    const createQuiz = async () => {
        setLoading(true);
        try {
            // const updatedQuestions = addExtraData();
            setProgress({ percentage: -1, msg: 'Uploading images...' });
            const questionsWithImgUrl = await handleUploadImgs(filteredQuestionsData);
            setProgress({ percentage: -1, msg: 'Creating quiz...' });
            const quizData = await handleCreateQuiz();
            setProgress({ percentage: -1, msg: 'Uploading questions...' });
            if (quizData) {
                for (let i = 0; i < questionsWithImgUrl.length; i++) {
                    const element = questionsWithImgUrl[i];
                    const question = {
                        ...element.question,
                        matches: element.question.matches ?? [],
                        sectionId:
                            quizData.sections.length === 1
                                ? quizData.sections[0].id
                                : quizData.sections.find((section) => {
                                      const sectionId = element.question.sectionId;
                                      // Ensure sectionId is a string before splitting
                                      if (typeof sectionId === 'string') {
                                          return section.sectionName === sectionId.split(',')[0];
                                      }
                                      return false;
                                  })?.id,
                    };
                    if (element.selectedQue?.id) {
                        const requestData = {
                            questionId: element.selectedQue.id,
                            qOrder: question.qOrder,
                            sectionId: question.sectionId,
                            positiveMarks: question.positiveMarks,
                            negativeMarks: question.negativeMarks,
                            partialMarks: question.partialMarks,
                        };
                        await QuizService.attachQuestion(quizData.id, requestData);
                    } else {
                        await QuizService.addQuestion(quizData.id ?? 0, question);
                    }
                }
            }
            resetStates();
        } catch (error) {
            toast.error(JSON.stringify(error));
        } finally {
            setLoading(false);
            setProgress({ percentage: 0, msg: '' });
        }
    };

    const columns = [
        {
            name: 'S.No.',
            cell: (row: any, rowIndex: number) => rowIndex + 1,
            sortable: false,
            width: '5%',
        },
        {
            name: 'Question',
            selector: (row: any) => row.question?.qText || 'N/A',
            sortable: false,
            width: '75%',
            cell: (row: any) => <KatexRenderer content={row.question?.qText} />,
        },
        {
            name: 'Status',
            cell: (row: any, rowIndex: number) => (row?.selectedQue?.id ? 'Reused' : 'New'),
            sortable: false,
            width: '10%',
        },
        {
            name: 'Similar',
            width: '10%',
            cell: (row: any) => (
                <span onClick={() => handleShowModal(row)} className='text-decoration-underline text-primary cursor-pointer'>
                    {row.results.length} matches found
                </span>
            ),
            sortable: false,
        },
    ];

    const handleShowModal = (rowData: any) => {
        setSelectedQuestionData(rowData);
        setShowModal(true);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleConfirm = () => {
        setFilteredQuestionsData((prev) => {
            // Merge new questions from selectedAnswers into selectedQuestions
            const merged = [...prev];
            const index = merged.findIndex(
                (item) => item.question.qText === selectedQuestionData.question.qText
            );
            if (index >= 0) {
                // Update the existing question's results
                merged[index].selectedQue = selectedQuestionData.selectedQue;
            } else {
                // Add the new question-answer pair
                merged.push(selectedQuestionData);
            }
            return merged;
        });
        setShowModal(false);
        setSelectedQuestionData({ question: [], results: [], selectedQue: null });
    };

    return (
        <div className="dashboard-page">
            <div className="container">
                Download test from Spayee and upload image files here.
                <br />
                <FloatingLabel className="my-3" controlId="quizType" label="Quiz Type">
                    <Form.Select
                        name="quizType"
                        defaultValue="Quiz"
                        onChange={(e) => setQuizType(e.target.value)}
                        // value={selec}
                    >
                        <option value={QuizType.QUIZ}>Quiz</option>
                        <option value={QuizType.PRACTICE_QUIZ}>Practice Quiz</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel controlId="quizTimeLimit" label="Time Limit (Minutes)">
                    <Form.Control
                        type="number"
                        placeholder="In Minutes"
                        value={quizDetails.timeLimit}
                        className="my-3"
                        onChange={(e) => {
                            setQuizDetails((prev) => ({
                                ...prev,
                                timeLimit: +e.target.value,
                            }));
                        }}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="minTimeSubmission"
                    label="Min Time to Submission (Minutes)"
                >
                    <Form.Control
                        className="my-3"
                        type="number"
                        placeholder="In Minutes"
                        value={quizDetails.minTimeSubmission}
                        onChange={(e) => {
                            setQuizDetails((prev) => ({
                                ...prev,
                                minTimeSubmission: +e.target.value,
                            }));
                        }}
                    />
                </FloatingLabel>
                <input
                    ref={inputRef}
                    type="file"
                    className="mb-3"
                    onChange={handleFileUpload}
                    multiple
                    accept=".json,image/*"
                />
                <br />
                {!filteredQuestionsData && (
                    <Button disabled={loading} onClick={createTest}>
                        Save Test
                    </Button>
                )}
                {filteredQuestionsData?.length > 0 && (
                    <>
                        <DataTable
                            columns={columns}
                            data={filteredQuestionsData?.filter(
                                (queData) => queData.results.length > 0
                            )}
                            progressPending={loading}
                            pagination
                            highlightOnHover
                            striped
                            noDataComponent="No data available"
                            className="mt-2"
                        />
                        <Button onClick={createQuiz} disabled={loading}>
                            Save Quiz
                        </Button>
                    </>
                )}
                {progress.percentage !== 0 && (
                    <>
                        <ProgressBar
                            now={progress.percentage !== -1 ? progress.percentage : 100}
                            animated
                            className="mt-4"
                            style={{ border: '1px solid gray' }}
                        />
                        <div>
                            {progress.percentage !== -1
                                ? `${progress.msg} - ${progress.percentage}%`
                                : `${progress.msg}`}
                        </div>
                    </>
                )}
            </div>
            <div>
                {showModal && (
                    <SimilarQuestionQuizModal
                        showModal={showModal}
                        selectedQuestionData={selectedQuestionData}
                        handleHideModal={handleHideModal}
                        handleConfirm={handleConfirm}
                        setSelectedQuestionData={setSelectedQuestionData}
                    />
                )}
            </div>
        </div>
    );
}

export default UploadQuizPage;
