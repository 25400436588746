import { useEffect, useState } from 'react';
import { PermissionResponse } from '../../types/permission.types';
import ESaralLoader from '../../components/Spinner/ESaralLoader';
import permissionService from '../../services/ApiServices/permission-service';
import DataTable from 'react-data-table-component';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import CreateEditPermission from '../../components/users/create-edit-permission';
import useGetPermission from '../../hooks/useGetPermission';
import PermissionDenied from '../../components/permission-denied/permission-denied';
import MainBtn from '../../components/btn/main-btn';

const PermissionsPage = () => {
    const [permissions, setPermissions] = useState<PermissionResponse[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [editId, setEditId] = useState<undefined | number>(undefined);
    const handlePermissionModal = (show: boolean) => setShowPermissionModal(show);
    const { canCreatePermission } = useGetPermission();

    const fetchPermissions = () => {
        setLoading(true);
        permissionService
            .getAllPermission()
            .then((res) => {
                if (res.status === 'success') {
                    setPermissions(res.data.permissions);
                } else {
                    toast.error('Failed to fetch permissions');
                }
            })
            .catch(() => toast.error('Something went wrong'))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchPermissions();
    }, []);

    if (!canCreatePermission) {
        return <PermissionDenied message="you don't have access of permissions" />
    }

    // const handleDelete = (id: number) => {
    //     if (window.confirm('Are you sure you want to delete this permission?')) {
    //         permissionService
    //             .deletePermission(id)
    //             .then((res) => {
    //                 if (res.status === 'success') {
    //                     toast.success('Permission deleted successfully');
    //                     fetchPermissions();
    //                 } else {
    //                     toast.error(res.message);
    //                 }
    //             })
    //             .catch(() => toast.error('Failed to delete permission'));
    //     }
    // };

    const columns = [
        {
            name: 'Slug',
            selector: (row: PermissionResponse) => row.slug,
            sortable: true,
        },
        {
            name: 'Description',
            selector: (row: PermissionResponse) => row.description,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row: PermissionResponse) => (
                <div className="d-flex gap-2">
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                            setEditId(row.id);
                            setShowPermissionModal(true);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ),
        },
    ];

    const handleHide = () => {
        handlePermissionModal(false);
        setEditId(undefined);
    };
    const handleSuccess = () => {
        setEditId(undefined);
        handlePermissionModal(false);
        fetchPermissions();
    };
    if (isLoading) return <ESaralLoader />;

    return (
        <div className='mx-3'>
            <h3>Permissions Management</h3>
            <DataTable
                columns={columns}
                data={permissions}
                pagination
                highlightOnHover
                striped
                responsive
                progressPending={isLoading}
                noDataComponent={<div>No permissions available</div>}
                customStyles={{
                    table: {
                        style: {
                            width: '90%',
                            margin: 'auto'
                        }
                    }
                }}
            />
            <Modal show={showPermissionModal} onHide={handleHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Permission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateEditPermission id={editId} onSuccess={handleSuccess} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <MainBtn
                message="Create Permission"
                onClick={() => setShowPermissionModal(true)}
            />
        </div>
    );
};

export default PermissionsPage;
