import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavbarVertical } from '../components'
import { Outlet } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch } from 'react-redux';
import { NavBarConstants } from '../Redux/nav-bar/nav-bar-constants';
import Loader from '../components/loader/loader';
import { getAllPermissions, mapMenuToFrontendUrls } from '../utils/helpers';


function Root() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(
    () => {
      const tkn = localStorage.getItem('@user')
      if (!tkn) {
        navigate('/login')
      } else {
        const FRONTEND_ROUTES = mapMenuToFrontendUrls();
        const allPermissions = getAllPermissions();
        dispatch({ type: NavBarConstants.FRONTEND_ROUTES, payload: FRONTEND_ROUTES })
        dispatch({ type: NavBarConstants.PERMISSIONS, payload: allPermissions })
      }
    }, []
  )

  const handleShow = () => dispatch({ type: NavBarConstants.IS_NAVBAR, payload: true });


  return (
    <>
      <Loader />
      <div className='d-flex' style={{
        color: 'black'
      }}>
        <NavbarVertical />
        <div className='w-100 d-flex flex-column' >
          <div className='navbar-horizontal d-block d-md-none'>
            <GiHamburgerMenu onClick={handleShow} color='#fff' style={{
              width: '30px',
              height: '30px',
            }} />
          </div>
          <div className='p-2' style={{
            height: '100vh',
            overflow: 'auto'
          }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default Root