import { Tab, Tabs } from 'react-bootstrap';
import './styles.css';
import BasicDetailsTab from './tab-items/basic-details.tab';
import { useCallback, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import AdditionalDetailsTab from './tab-items/additional-details.tab';
import SetTabs from './tab-items/sets.tab';
import CourseService from '../../services/ApiServices/course-service';
import { SingleCoursePayload, COURSE_STATUS, CreatePayload } from '../../types/course.types';
import { validateString, validateNumber, getSessionDiff } from '../../utils/helpers';
import SpecialButton from '../btn/special-btn';
import toast from 'react-hot-toast';
import { useAppSelector } from '../../Redux/hooks';

export const createCourseLoader = async ({ _, params }: any) => {
    const courseId = params?.courseId;
    if (!courseId) return null;
    return CourseService.getCourseById(courseId).then((res) => res.data.course);
};

type BasicDetails = Omit<
    SingleCoursePayload,
    'hardwares' | 'sets' | 'courseClassTargets' | 'processes'
>;

const CreateCourse = () => {
    const data = useLoaderData() as SingleCoursePayload;
    const navigate = useNavigate();
    const FRONTEND_ROUTES = useAppSelector(state => state.NavBarReducer.frontendRoutes);
    const [basicDetails, setBasicDetails] = useState<BasicDetails>(
        data || {
            name: '',
            description: '',
            thumbnail: 'https://unsplash.com/photos/green-leafed-plants-yTNrkM7pHgI',
            academicEndDate: '',
            academicStartDate: Date.now(),
            status: COURSE_STATUS.DRAFT,
            courseFee: 0,
            discountedFee: 0,
            minSalePrice: 0,
            availableOnStore: false,
            availableToSales: false,
            salesStartDate: Date.now(),
            orderEnrollment: 1,
            orderStore: 1
        }
    );

    const handleInputChange = useCallback(
        (name: string, value: any) => {
            setBasicDetails((prev) => ({ ...prev, [name]: value }));
        },
        [basicDetails]
    );

    const submitCourse = () => {
        if (!validateString(basicDetails.name)) return toast.error('Course name is required*');
        if (!validateNumber(basicDetails.courseFee))
            return toast.error('Enter a valid amount for course fees!');
        if (!validateNumber(basicDetails.discountedFee))
            return toast.error('Enter a valid amount for discount fees!');
        if (!validateNumber(basicDetails.minSalePrice))
            return toast.error('Enter a valid amount for Min sale fees!');
        if (!basicDetails?.academicStartDate)
            return toast.error('Enter a valid academic start date!');
        if (!validateString(basicDetails?.sessionStartYear))
            return toast.error('Session start year is required');
        if (!validateString(basicDetails.sessionEndYear))
            return toast.error('Session end year is required');
        if (!validateString(basicDetails.branding))
            return toast.error('Course Branding is required');
        if (!validateString(basicDetails.type)) return toast.error('Course type is required');
        if (!validateString(basicDetails.targetExam)) return toast.error('Target exam is required');
        if (!validateString(basicDetails.term)) return toast.error('Term is required');
        if (!validateString(basicDetails.phase)) return toast.error('Phase is required');
        if (!basicDetails?.academicEndDate) return toast.error('Enter a valid academic end date!');
        if (!basicDetails?.salesStartDate) return toast.error('Enter a valid sales start date!');
        if (Number(basicDetails.discountedFee) > Number(basicDetails.courseFee)) {
            return toast.error('Discount fee should be less than the actual fee');
        }
        if (new Date(basicDetails.academicStartDate) > new Date(basicDetails.academicEndDate)) {
            return toast.error('Course start date should be less than the course end date');
        }
        if (new Date(basicDetails.salesStartDate) > new Date(basicDetails.academicEndDate)) {
            return toast.error('Sales start date should be less than the course end date');
        }
        const {
            salesStartDate,
            academicEndDate,
            academicStartDate,
            id = null,
            ...rest
        } = basicDetails;
        const startDate = new Date(academicStartDate).toISOString();
        const endDate = new Date(academicEndDate).toISOString();
        const saleDate = new Date(salesStartDate).toISOString();
        const payload: CreatePayload = {
            name: rest.name?.trim(),
            thumbnail: rest.thumbnail,
            description: rest.description,
            courseFee: Number(rest.courseFee),
            discountedFee: Number(rest.discountedFee),
            minSalePrice: Number(rest.minSalePrice),
            salesStartDate: saleDate,
            academicEndDate: endDate,
            academicStartDate: startDate,
            status: rest.status,
            availableToSales: rest.availableToSales,
            availableOnStore: rest.availableOnStore,
            sessionStartYear: rest?.sessionStartYear,
            sessionEndYear: rest.sessionEndYear,
            group: rest.group,
            term: rest.term,
            phase: rest.phase,
            targetExam: rest.targetExam,
            type: rest.type,
            branding: rest.branding,
            promotionalVideoId: rest.promotionalVideoId,
            showWithTax: rest.showWithTax,
            orderStore: Number(rest.orderStore || 1),
            orderEnrollment: Number(rest.orderEnrollment || 1)
        };

        if (data?.id) {
            CourseService.updateCourse(data.id, payload).then((res) => {
                toast.success(res.status);
                if (res.status === 'success') {
                    navigate(`${FRONTEND_ROUTES?.CONTENTS?.COURSES}/${data.id}`, { replace: true });
                }
            });
        } else
            CourseService.createCourse(payload).then((res) => {
                toast.success(res.status);
                if (res.status === 'success') {
                    navigate(`${FRONTEND_ROUTES?.CONTENTS?.COURSES}/${res.data.id}`, { replace: true });
                }
            });
    };

    const isDisabled =
        !data?.id ||
        !data?.sets ||
        data?.sets?.length == 0 ||
        !data?.courseClassTargets ||
        data?.courseClassTargets?.length == 0;
    const diff = !data?.sessionStartYear
        ? 0
        : getSessionDiff(data?.sessionStartYear, data?.sessionEndYear);

    const tempArr = Array.from({ length: diff + 1 }).map((_, i) => ({
        sessionStart: Number(data?.sessionStartYear) + i + '',
        sessionEnd: Number(data?.sessionStartYear) + i + 1 + '',
    }));

    const sessions = data?.classSessions?.length > 0 ? data?.classSessions : tempArr;

    return (
        <div className="d-flex flex-column gap-md-4 px-md-3 px-1 mb-5">
            <div className="d-md-flex px-md-3 mt-3 gap-5 d-block">
                <span className="heading">{basicDetails?.name || 'Untitled'}</span>
            </div>
            <div>
                <Tabs defaultActiveKey="basic" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="basic" title="Basic">
                        <BasicDetailsTab
                            isStatusDisabled={isDisabled}
                            data={basicDetails}
                            handleInputChange={handleInputChange}
                        />

                        <div className="d-flex gap-4 mt-4">
                            <SpecialButton variant="btn-15" text="Save" onClick={submitCourse} />
                            <SpecialButton variant="btn-5" text="Cancel" />
                        </div>
                    </Tab>
                    <Tab eventKey="additional" title="Additional" disabled={!data?.id}>
                        <AdditionalDetailsTab
                            courseId={data?.id}
                            courseHardwares={data?.hardwares || []}
                            courseServices={data?.services || []}
                            courseProcesses={data?.processes || []}
                            courseTargets={data?.courseClassTargets || []}
                            courseClassSessions={sessions}
                        />
                    </Tab>
                    <Tab eventKey="sets" title="Sets" disabled={!data?.id}>
                        <SetTabs courseSets={data?.sets || []} />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default CreateCourse;
