import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { getMediaUrl } from "../../utils/helpers";

interface ViewImageProps {
    imageUrl: string;
    show: boolean;
    onHide: () => void;
}

const ViewImage = ({ imageUrl, show, onHide }: ViewImageProps) => {

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Image Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {imageUrl ? <img
                    src={getMediaUrl(imageUrl)}
                    alt="Preview"
                    // className="img-fluid rounded"
                    style={{ maxHeight: "450px" }}
                    width={'100%'}
                />
                    :
                    <p>No image to preview</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewImage;
