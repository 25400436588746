import { useContext, useState } from 'react';
import { PiStudentBold } from 'react-icons/pi';
import { FaBusinessTime, FaSignOutAlt } from 'react-icons/fa';
import { BiSolidBookContent } from 'react-icons/bi';
import { TbReport } from 'react-icons/tb';
import { CiFlag1 } from 'react-icons/ci';
// import { Menu } from '../types/types';
import {
    Accordion,
    AccordionContext,
    ListGroup,
    Offcanvas,
    useAccordionButton,
} from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InitialStateType } from '../Redux/nav-bar/nav-bar';
import { NavBarConstants } from '../Redux/nav-bar/nav-bar-constants';
import { IoIosArrowDropleftCircle, IoIosArrowForward, IoIosArrowUp } from 'react-icons/io';
import { FRONTEND_URLS } from '../config';
import { getUserMenu } from '../utils/helpers';
import useGetPermission from '../hooks/useGetPermission';
// import { useAppDispatch } from '../Redux/hooks';

type MenuItemType = {
    name: string | React.ReactNode;
    children?: MenuItemType[];
    onClick?: () => void;
    navigationPath?: string;
    permissions?: string[]
};

const NavIcons = [
    <PiStudentBold key={'student'} className="mx-2" size={20} />,
    <FaBusinessTime key={'business'} className="mx-2" size={20} />,
    <BiSolidBookContent key={'book'} className="mx-2" size={20} />,
    <TbReport className="mx-2" key={'report'} size={20} />,
    <CiFlag1 className="mx-2" size={20} key={'flag'} />,
];


// const ProfileRoute: MenuItemType = {
//     name: 'Profile',
//     children: [
//         {
//             name: 'Setting',
//             children: [],
//             onClick: () => console.log('Setting'),
//             navigationPath: FRONTEND_URLS.SETTING,
//         },
//         {
//             name: (
//                 <span
//                     onClick={() => {
//                         localStorage.clear();
//                         // window.location.reload();
//                     }}
//                     className="nav-bar-span"
//                     style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
//                 >
//                     <FaSignOutAlt style={{ marginRight: '8px' }} />
//                     Logout
//                 </span>
//             ),
//             children: [],
//             onClick: () => console.log('Subject Groups'),
//             navigationPath: FRONTEND_URLS.LOGIN,
//         },
//     ],
// }

const ProfileRoute: MenuItemType = {
    name: (
        <span
            onClick={() => {
                localStorage.clear();
            }}
            className="nav-bar-span"
            // style={{ cursor: 'pointer', display: 'flex', alignItems: 'center'}}
        >
            <FaSignOutAlt style={{ marginRight: '8px' }} />
            Logout
        </span>
    ),
    children: [],
    onClick: () => console.log('Subject Groups'),
    navigationPath: FRONTEND_URLS.LOGIN,
}

const Menus: MenuItemType[] = [
    {
        name: 'Engagement',
        children: [
            {
                name: 'Doubt Tool',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.ENGAGMENT.DOUBT_TOOL,
            },
            {
                name: 'Notification',
                children: [],
                onClick: () => console.log('Notification Groups'),
                navigationPath: FRONTEND_URLS.ENGAGMENT.NOTIFICATION,
            },
        ],
    },
    {
        name: 'Students',
        children: [
            {
                name: 'All',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.STUDENT.ALL_USERS,
            },
            {
                name: 'Enrolled Students',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.STUDENT.ENROLLED_STUDENTS,
            },
            // {
            //     name: 'All Users',
            //     children: [],
            //     onClick: () => console.log('All Users'),
            //     navigationPath: FRONTEND_URLS.STUDENT.STUDENT_ALL_USERS,
            // },
        ],
    },
    {
        name: 'Bd',
        children: [
            {
                name: 'Order',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.BD.ORDER,
            },
            {
                name: 'Payments',
                children: [],
                onClick: () => console.log('Subjects'),
                navigationPath: FRONTEND_URLS.BD.PAYMENTS,
            },
        ],
    },
    {
        name: 'Content',
        children: [
            {
                name: 'Node',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.CONTENTS.NODE,
            },
            {
                name: 'Set',
                children: [],
                onClick: () => console.log('Subjects'),
                navigationPath: FRONTEND_URLS.CONTENTS.SETS,
            },
            {
                name: 'Courses',
                children: [],
                onClick: () => console.log('Chapters'),
                navigationPath: FRONTEND_URLS.CONTENTS.COURSES,
            },
            {
                name: 'Quiz',
                children: [],
                navigationPath: FRONTEND_URLS.CONTENTS.QUIZ,
            },
            {
                name: 'Live Dashboard',
                children: [],
                navigationPath: FRONTEND_URLS.CONTENTS.LIVE_MANAGMENT + `/live`,
            },
        ],
    },
    {
        name: 'Academic Admin',
        children: [
            {
                name: 'Subject Groups',
                children: [],
                onClick: () => console.log('Subject Groups'),
                navigationPath: FRONTEND_URLS.ADMIN.ADMIN_ACADMIC + '/subject-group',
            },
            {
                name: 'Subjects',
                children: [],
                onClick: () => console.log('Subjects'),
                navigationPath: FRONTEND_URLS.ADMIN.ADMIN_ACADMIC + '/subject',
            },
            {
                name: 'Chapters',
                children: [],
                onClick: () => console.log('Chapters'),
                navigationPath: FRONTEND_URLS.ADMIN.ADMIN_ACADMIC + '/chapter',
            },
            {
                name: 'Content Groups',
                children: [],
                onClick: () => console.log('Content Groups'),
                navigationPath: FRONTEND_URLS.ADMIN.ADMIN_ACADMIC + '/content-group',
            },
            {
                name: 'Exam Target',
                children: [],
                onClick: () => console.log('Content Groups'),
                navigationPath: FRONTEND_URLS.ADMIN.EXAM_TARGETS,
            },
            {
                name: 'Student Classes',
                children: [],
                onClick: () => console.log('Content Groups'),
                navigationPath: FRONTEND_URLS.ADMIN.STUDENT_CLASSES,
            },
            {
                name: 'Collection',
                children: [],
                navigationPath: FRONTEND_URLS.ADMIN.COLLECTION,
            },
            {
                name: 'Testimonials',
                children: [],
                navigationPath: FRONTEND_URLS.ADMIN.TESTIMONIAL,
            },
        ],
        onClick: () => console.log('Academic'),
        navigationPath: FRONTEND_URLS.ADMIN.LEVEL + '/0',
    },
    {
        name: 'Enrollment Admin',
        children: [
            {
                name: 'Services',
                children: [],
                onClick: () => console.log('Content Groups'),
                navigationPath: FRONTEND_URLS.ADMIN.SERVICES,
            },
            {
                name: 'Processes',
                children: [],
                onClick: () => console.log('Content Groups'),
                navigationPath: FRONTEND_URLS.ADMIN.PROCESSES,
            },
            {
                name: 'Hardwares',
                children: [],
                onClick: () => console.log('Content Groups'),
                navigationPath: FRONTEND_URLS.ADMIN.HARDWARES,
            },
            {
                name: 'Demo Courses',
                children: [],
                onClick: () => console.log('Demo Courses'),
                navigationPath: FRONTEND_URLS.ADMIN.DEMO_COURSES,
            },
            {
                name: 'Roles',
                children: [],
                // onClick: () => console.log('Demo Courses'),
                navigationPath: FRONTEND_URLS.ROLES,
            },
            {
                name: 'Permissions',
                children: [],
                // onClick: () => console.log('Demo Courses'),
                navigationPath: FRONTEND_URLS.PERMISSIONS,
            },
        ],
        onClick: () => console.log('Content Groups'),
        navigationPath: FRONTEND_URLS.ADMIN.SERVICES,
    },
    
    ProfileRoute
];



// const permitMenu = getUserMenu()

// Recursive function to process menu items
const processMenuItems = (menu: any[], parentUrl: string = ''): MenuItemType[] => {
    return menu.map((item: any) => {
        const fullUrl = `${parentUrl}${item.url}`; // Concatenate parent and child URLs

        const menuItem: MenuItemType = {
            name: <span
                className="nav-bar-span"
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
                {item.icon ? <img src={item.icon ?? ''} alt={item.name} style={{ marginRight: '8px', width: '20px' }} /> : ''}
                {item.name}
            </span>,
            navigationPath: fullUrl,
            children: item.menu ? processMenuItems(item.menu, fullUrl) : [],
            permissions: item.permissions
        };

        // Example: Adding onClick logic to specific items (like "Doubt Tool")
        if (item.name === "Doubt Tool") {
            menuItem.onClick = () => console.log('Subject Groups');
        }

        return menuItem;
    });
};

// Main function to convert permitMenu into MenuItemType[]
const convertToMenuItemType = (): MenuItemType[] => {
    const result: MenuItemType[] = [];
    const permitMenu = getUserMenu();

    for (const key in permitMenu) {
        const item = permitMenu[key];

        const menuItem: MenuItemType = {
            name: <span
                className="nav-bar-span"
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
                {item.icon ? <img src={item.icon ?? ''} alt={item.name} style={{ marginRight: '8px', width: '20px' }} /> : ''}
                {item.name}
            </span>,
            navigationPath: item.url,
            children: item.menu ? processMenuItems(item.menu, item.url) : [],
            permissions: item.permissions,
        };

        result.push(menuItem);
    }

    return result;
};

const CustomToggle = ({ children, eventKey }: any) => {
    const { activeEventKey } = useContext(AccordionContext);
    const isCurrentEventKey = activeEventKey === eventKey;

    const decoratedOnClick = useAccordionButton(eventKey);
    return (
        <div
            className="d-flex align-items-center justify-content-between"
            onClick={decoratedOnClick}
            style={{ cursor: 'pointer' }}
        >
            <Link to="#" className="nav-link">
                {children}
            </Link>
            {isCurrentEventKey ? <IoIosArrowUp color="#fff" /> : <IoIosArrowForward color="#fff" />}
        </div>
    );
};

const RenderMenuItems = ({ items, depth = 0 }: { items: MenuItemType[], depth?: number }) => {

    return items.map((item, index) => {
        let navUrl = item.navigationPath || '';

        const urlCorrection: Record<string, string> = {
            '/contents/live-dashboard': '/contents/live-dashboard/live',
            "/academic-admin/subject-group": "/admin/level/0",
            "/academic-admin/subject": "/admin/level/1",
            "/academic-admin/chapter": "/admin/level/2",
            "/academic-admin/content-group": "/admin/level/3"
        }

        navUrl = urlCorrection[navUrl] || navUrl

        return <ListGroup.Item as="li" bsPrefix="nav-item" key={`${depth}-${index}`}>
            {item.children && item.children.length > 0 ? (
                <Accordion>
                    <CustomToggle eventKey={`${depth}-${index}`}>{item.name}</CustomToggle>
                    <Accordion.Collapse eventKey={`${depth}-${index}`}>
                        <ListGroup as="ul" className="nav flex-column ms-3">
                            <RenderMenuItems items={item.children} depth={depth + 1} />
                        </ListGroup>
                    </Accordion.Collapse>
                </Accordion>
            ) : (
                <NavLink
                    to={navUrl || '/'}
                    style={{ textDecoration: 'none' }}
                >
                    {({ isActive }) => {

                        return (
                            <ListGroup.Item
                                key={index}
                                // as="li"
                                bsPrefix="nav-item"
                                className={`ps-3 my-1 nav-bar-li ${isActive ? 'active-link' : ''}`}
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                {item.name}
                            </ListGroup.Item>
                        )
                    }
                    }
                </NavLink>
            )}
        </ListGroup.Item>
    });
};

const MenuItems = () => {
    const menu = convertToMenuItemType();
    const { isAdmin } = useGetPermission()
    return (
        <Accordion defaultActiveKey="0" as="ul" className="navbar-nav flex-column mt-3">
            <ListGroup.Item
                as="li"
                bsPrefix="nav-item"
                className="mb-2"
                style={{ alignSelf: 'center' }}
            >
                <img src={require('../assets/images/esaral_logo.png')} style={{ width: '48px' }} />
            </ListGroup.Item>
            {isAdmin ? <RenderMenuItems items={Menus} /> : <RenderMenuItems items={menu} />}
            <hr />
            <RenderMenuItems items={[ProfileRoute]} />
        </Accordion>
    )
}

function NavbarVertical() {
    const [isOpen, setIsOpen] = useState(true);

    const show = useSelector<{ NavBarReducer: InitialStateType }>(
        (state) => state?.NavBarReducer.isNavbar
    );
    const dispatch = useDispatch();

    const handleClose = () =>
        dispatch({ type: NavBarConstants.IS_NAVBAR, action: { payload: false } });

    const toggleSidebar = () => setIsOpen(!isOpen);

    return (
        <>
            <div className={`navbar-vertical d-none d-md-block ${isOpen ? 'open' : 'closed'}`}>
                <div
                    className="position-absolute toggle-button"
                    style={{ right: '-30px', bottom: '0px' }}
                >
                    <IoIosArrowDropleftCircle
                        onClick={toggleSidebar}
                        color="black"
                        size={30}
                        className={`nav-bar-vertical-toggle ${isOpen ? 'rotate-left' : 'rotate-right'
                            }`}
                    />
                </div>
                <div style={{ width: '250px', height: '100vh', overflow: 'auto' }} className="p-3">
                    <MenuItems />
                </div>
            </div>
            <div className="d-none d-sm-block">
                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton style={{ background: 'black', color: 'white' }} />
                    <Offcanvas.Body style={{ background: 'black' }}>
                        <MenuItems />
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
}

export default NavbarVertical;