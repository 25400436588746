import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Card, Col, FloatingLabel, Form, Row, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
// import Select from 'react-select';
import { BACKEND_URLS } from '../../config';
import {
    Action,
    amountDetailsType,
    courseDetailsType,
    // shipmentHardwareType,
} from '../../pages/bd/order/order-form';
import { axiosClient } from '../../services/axiosClient';
import { hardwaresInterface } from '../../types/ApiTypes';
import { courseInterface, offeredSerivceType } from '../../types/order';
import useGetPermission from '../../hooks/useGetPermission';
import { useParams } from 'react-router-dom';

// interface targetInterfaces {
//     id: number;
//     name: string;
//     createdById: number | null;
//     createdAt: string;
//     updatedAt: string;
// }

export default function CourseDetatils({
    courseDetails,
    setCourseDetails,
    setAmountDetails,
    courseId,
}: {
    courseId: number | undefined;
    courseDetails: courseDetailsType;
    setCourseDetails: React.Dispatch<React.SetStateAction<courseDetailsType>>;
    setAmountDetails: React.Dispatch<React.SetStateAction<amountDetailsType>>;
}) {
    const [targetExams, setTargetExams] = useState<{ label: string; value: number }[]>(
        []
    );
    const [courses, setCourses] = useState<{ label: string; value: number }[]>([]);
    const [allCourses, setAllCourses] = useState<{ label: string; value: courseInterface }[]>([]);
    const [services, setServices] = useState<offeredSerivceType[]>([]);
    const [hardwares, setHardwares] = useState<hardwaresInterface[]>([]);
    // const [defaultCourse, setDefaultCourse] = useState<{
    //     label: string;
    //     value: number;
    // }>({
    //     label: '',
    //     value: 0,
    // });
    const [selectedTarget, setSelectedTarget] = useState<number | null>(null);
    const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
    const [currentCourseValidity, setCurrentCourseValidity] = useState('');
    const { isLctAdminRole } = useGetPermission();
    const { orderId } = useParams();

    useEffect(() => {
        // setDefaultCourse({ label: courseDetails.course.name, value: courseDetails.course.id || 0 });
        setSelectedCourse(courseDetails.course.id || 0);
        const selectedCourse = allCourses.find((course) => course.value.id === courseDetails.course.id);
        if (selectedCourse) {
            setServices(selectedCourse?.value?.courseServices || []);
            setHardwares(selectedCourse?.value?.courseHardware || []);
            const selectedCourseTarget = selectedCourse?.value?.courseClassTargets?.find((crs) => targetExams.some((target) => target.value === crs.targetId))
            if (selectedCourseTarget) {
                handleTargetChange({ target: { value: selectedCourseTarget.targetId } }, 'initial')
            }
        }
    }, [courseDetails.course, targetExams, allCourses]);

    useEffect(() => {
        // axiosClient
        //     .get(BACKEND_URLS.SERVIECES)
        //     .then((res: AxiosResponse) => {
        //         const servicesData = res.data.data.services as offeredSerivceType[];
        //         setServices(servicesData);
        //     })
        //     .catch((err: AxiosError) => {
        //         toast.error(err.message);
        //     });

        axiosClient
            .get(BACKEND_URLS.TARGETS)
            .then((res: AxiosResponse) => {
                const targets = res.data.data.targets.map((target: any) => ({
                    value: target.id,
                    label: target.name,
                }));
                setTargetExams(targets);
            })
            .catch((err: AxiosError) => {
                toast.error(err.message);
            });

        // axiosClient
        //     .get(BACKEND_URLS.HARDWARS)
        //     .then((res: AxiosResponse) => {
        //         const hardwares = res.data.data.hardwares.map((hardwar: any) => ({
        //             ...hardwar,
        //             qty: 0,
        //         }));
        //         setHardwares(hardwares);
        //     })
        //     .catch((err: AxiosError) => {
        //         toast.error(err.message);
        //     });

        axiosClient
            .get(`/courses?availableToSales=true&status=PUBLISHED`)
            .then((res) => {
                const coursesData = res.data.data.courses.map((course: any) => {
                    if (course.id === courseId) {
                        setCurrentCourseValidity(course.academicEndDate);
                    } else {
                        setCurrentCourseValidity('');
                    }
                    return {
                        value: course,
                        label: course.name,
                    };
                });
                setAllCourses(coursesData);
            })
            .catch((error: any) => {
                toast.error(error.message);
            });
    }, [courseId]);

    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement & HTMLInputElement>) => {
        const { checked, value, id } = event.target;

        setCourseDetails((prevDetatils) => ({
            ...prevDetatils,
            [id]: id == 'isOffered' ? checked : value,
        }));

        if (id == 'currentValidity') {
            setCourseDetails((prevDetails) => {
                return {
                    ...prevDetails,
                    services: services.map((service) => {
                        const userService = courseDetails.services.find(
                            (uService) => uService.serviceId === service.id
                        );
                        const endDateChange =
                            userService?.endDate !== value &&
                            userService?.action &&
                            userService?.action !== Action.Delete;

                        return {
                            endDate: value,
                            serviceId: service.id,
                            serviceName: service.slug,
                            isOffered: userService?.isOffered ? userService?.isOffered : false,
                            slug: service.slug,
                            action:
                                userService?.action === undefined
                                    ? Action.NA
                                    : userService?.action === Action.New
                                        ? Action.New
                                        : userService?.action === Action.Delete
                                            ? Action.Delete
                                            : userService?.action === Action.NA
                                                ? Action.NA
                                                : userService?.action === Action.Empty
                                                    ? Action.Edit
                                                    : Action.Edit,
                        };
                    }),
                };
            });
        }
    };

    const handleTableChange = ({
        value,
        id,
        field,
        checked,
        serviceName,
    }: {
        value: string;
        id: number;
        field: string;
        checked?: boolean;
        serviceName: string;
    }) => {
        setCourseDetails((prevDetails) => {
            const serviceExists = prevDetails.services.find((service) => service.serviceId === id);
            if (serviceExists) {
                return {
                    ...prevDetails,
                    services: prevDetails.services.map((service) => {
                        return service.serviceId === id
                            ? {
                                ...service,
                                slug: serviceName,
                                [field]: field === 'isOffered' ? !!checked : value,
                                action:
                                    field === 'isOffered'
                                        ? !!checked
                                            ? service.action === Action.New ||
                                                service.action === Action.NA
                                                ? Action.New
                                                : Action.Edit
                                            : Action.Delete
                                        : service.action,
                            }
                            : service;
                    }),
                };
            } else {
                return {
                    ...prevDetails,
                    services: [
                        ...prevDetails.services,
                        {
                            serviceId: id,
                            slug: serviceName,
                            serviceName: serviceName,
                            endDate: prevDetails.currentValidity || '', // Set default to currentValidity
                            isOffered: field === 'isOffered' ? !!checked : false,
                            [field]: value,
                            action: Action.New,
                        },
                    ],
                };
            }
        });
    };

    const handleTargetChange = async (event: any, type?: string) => {
        const selectedOption = event.target.value;
        setCourseDetails((prevDetatils) => ({
            ...prevDetatils,
            targetExam: selectedOption,
        }));
        setCurrentCourseValidity('');
        if (type !== 'initial') {
            setCourseDetails((prevDetatils) => ({
                ...prevDetatils,
                course: {
                    id: null,
                    name: '',
                },
            }));
            setSelectedCourse(0);
            setServices([]);
            setHardwares([]);
        }
        // setDefaultCourse({
        //     label: '',
        //     value: 0,
        // });
        setSelectedTarget(selectedOption);
        const courses = allCourses
            ?.filter((course) =>
                course?.value?.courseClassTargets?.some(
                    (target) => target.targetId == selectedOption
                )
            )
            ?.map((course) => ({ label: course.label, value: course.value.id }));
        setCourses(courses ?? []);
    };

    const handleCourseChange = (event: any) => {
        const selectedOption = courses.find((course) => course.value == event.target.value);
        if (!selectedOption) return;

        const chooseCourse = allCourses.find((course) => course.value.id === selectedOption.value);
        // if (chooseCourse?.value?.courseServices) {
        setServices(chooseCourse?.value?.courseServices || [])
        // }
        // if (chooseCourse?.value?.courseHardware) {
        setHardwares(chooseCourse?.value?.courseHardware || [])
        // }
        setCourseDetails((prevDetails) => ({
            ...prevDetails,
            course: {
                id: selectedOption.value,
                name: selectedOption.label,
            },
        }));
        if (chooseCourse && chooseCourse?.value.discountedFee) {
            setCurrentCourseValidity(chooseCourse?.value.academicEndDate);
            setAmountDetails((prevDetatils) => ({
                ...prevDetatils,
                totalAmount: `${chooseCourse.value.discountedFee}`,
            }));
        }
        setSelectedCourse(selectedOption.value);
    };

    const handleHardwareIncrementAndDecrement = ({
        id,
        checked,
    }: {
        id: number;
        checked: boolean;
    }) => {
        setCourseDetails((prevDetails) => {
            const hardwares = [...prevDetails.hardwares];

            if (checked) {
                const existingHardware = hardwares.find((hardware) => hardware.hardwareId === id);
                if (!existingHardware) {
                    hardwares.push({
                        hardwareId: id,
                        action: Action.New,
                    });
                }
            } else {
                // Remove hardware if it exists in the array
                const index = hardwares.findIndex((hardware) => hardware.hardwareId === id);
                if (index !== -1) {
                    hardwares[index] = {
                        ...hardwares[index],
                        action: Action.Delete,
                    };
                }
            }
            return {
                ...prevDetails,
                hardwares,
            };
        });
    };

    const canEdit = orderId === '0' || isLctAdminRole;

    return (
        <Card className="m-2 border-0">
            <Card.Body>
                <Row id="course-details">
                    <Row>
                        <Col md={6} className='mb-2'>
                            <FloatingLabel label='Target Exam'>
                                {/* <Form.Group>
                                <Form.Label>Target Exam</Form.Label> */}
                                <Form.Select
                                    // options={targetExams}
                                    value={selectedTarget || 0}
                                    onChange={handleTargetChange}
                                    disabled={!canEdit}
                                    // placeholder="Target Exam"
                                    id="target"
                                >
                                    <option value={0}>Select Target</option>
                                    {targetExams?.map((exam) => (
                                        <option value={exam.value} key={exam.value}>{exam.label}</option>
                                    ))}
                                </Form.Select>
                                {/* </Form.Group> */}
                            </FloatingLabel>
                        </Col>
                        <Col md={6} className='mb-2'>
                            {courses.length > 0 || courseDetails.course ? (
                                <FloatingLabel label='Courses'>
                                    <Form.Select
                                        value={selectedCourse || 0}
                                        onChange={handleCourseChange}
                                        id="course"
                                        disabled={!canEdit}
                                    >
                                        <option value={0}>Select Course</option>
                                        {courses?.map((crs) => (
                                            <option key={crs.value} value={crs.value}>{crs.label}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                                // <Form.Group>
                                //     <Form.Label>Courses</Form.Label>
                                //     <Select
                                //         value={defaultCourse}
                                //         options={courses}
                                //         onChange={handleCourseChange}
                                //         placeholder="Courses"
                                //         id="course"
                                //     />
                                // </Form.Group>
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FloatingLabel label='Current Validty'>
                                <Form.Control
                                    type="date"
                                    id="currentValidity"
                                    onChange={handleOnChange}
                                    value={courseDetails.currentValidity || ''}
                                    min={(date => {
                                        date.setDate(date.getDate() - 1);
                                        return date.toISOString().split('T')[0];
                                    })(new Date())}
                                    max={
                                        currentCourseValidity.length > 0
                                            ? new Date(currentCourseValidity)
                                                .toISOString()
                                                .split('T')[0]
                                            : ''
                                    }
                                />
                            </FloatingLabel>
                            {/* <Form.Group>
                                <Form.Label>Current Validty</Form.Label>
                                <Form.Control
                                    type="date"
                                    id="currentValidity"
                                    onChange={handleOnChange}
                                    value={courseDetails.currentValidity || ''}
                                    min={new Date().toISOString().split('T')[0]}
                                    max={
                                        currentCourseValidity.length > 0
                                            ? new Date(currentCourseValidity)
                                                .toISOString()
                                                .split('T')[0]
                                            : ''
                                    }
                                />
                            </Form.Group> */}
                        </Col>
                    </Row>

                    {services?.length > 0 && <Row>
                        <div className="table-wrapper">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Services</th>
                                        {/* {orderId !== '0' && <th>End Date</th>} */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {services.map((service, index) => {
                                        const userService = courseDetails.services.find(
                                            (uService) =>
                                                uService.serviceId === service.id &&
                                                uService.action !== 'DELETE'
                                        );

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="checkbox"
                                                        id={`service-${index}`}
                                                        checked={
                                                            userService
                                                                ? userService.isOffered
                                                                : false
                                                        }
                                                        onChange={(e) =>
                                                            handleTableChange({
                                                                value: e.target.value,
                                                                id: service.id,
                                                                field: 'isOffered',
                                                                checked: e.target.checked,
                                                                serviceName: service.slug,
                                                            })
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`service-${index}`}
                                                    >
                                                        {service.name}
                                                    </label>
                                                </td>
                                                {/* {
                                                    orderId !== '0'
                                                    && <td>
                                                        <Form.Control
                                                            type="date"
                                                            value={userService?.endDate ? userService.endDate : ''}  // Ensure valid date format or empty string
                                                            onChange={(e) => { handleTableChange({ value: e.target.value, id: service.id, field: "endDate", serviceName: service.name }) }}
                                                        />
                                                    </td>
                                                } */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Row>}

                    {hardwares?.length > 0 && <Row>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Items</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hardwares.map((hardware, index) => {
                                    const userHardware = courseDetails.hardwares.find(
                                        (uHardware) =>
                                            uHardware.hardwareId === hardware.id &&
                                            uHardware.action !== 'DELETE'
                                    );
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    id={`hardware-${index}`}
                                                    checked={!!userHardware}
                                                    onChange={(e) =>
                                                        handleHardwareIncrementAndDecrement({
                                                            id: hardware.id,
                                                            checked: e.target.checked,
                                                        })
                                                    }
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`hardware-${index}`}
                                                >
                                                    {hardware.name}
                                                </label>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Row>}
                </Row>
            </Card.Body>
        </Card>
    );
}
