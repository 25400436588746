import { useAppSelector } from '../Redux/hooks';

function usePermit() {
    const permissions = useAppSelector((state) => state.NavBarReducer.permissions);

    // Optional: Create a helper function to check specific permissions
    const hasPermission = (permission: string) => {
        return permissions?.includes('admin') || permissions?.includes(permission);
    };

    // Return permissions and helper function
    return { permissions, hasPermission };
}

export default usePermit;
