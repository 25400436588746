"use client";
import { lazy, useEffect, useState } from "react";
import QuizDetails from "./QuizDetails";
import { Accordion } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";

const QuizQuestionDetails = lazy(() => import("./QuizQuestionDetails"));

const QuizEdit = () => {
  const params = useParams();
  const quizId = params?.quizId ? parseInt(params.quizId) : undefined;
  const location = useLocation().pathname;
  const [activeKey, setActiveKey] = useState<"0" | "1">("0");

  useEffect(() => {
    const newActiveKey = (`/contents/quiz/edit-quiz/${quizId}` === location) ? "1" : "0";
    setActiveKey(newActiveKey);
  }, [location, quizId]);

  return (
    <div className="container" style={{
      position: 'relative',
    }}>
      {/* Use activeKey prop to control the accordion */}
      <Accordion className="mt-3" activeKey={activeKey} onSelect={(e) => setActiveKey(e as "0" | "1")}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Create/Edit Quiz</Accordion.Header>
          <Accordion.Body>
            <QuizDetails quizId={quizId} />
          </Accordion.Body>
        </Accordion.Item>

        {quizId && quizId !== 0 && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>Create/Edit Questions</Accordion.Header>
            <Accordion.Body>
              <QuizQuestionDetails quizId={quizId} />
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </div>
  );
};

export default QuizEdit;