import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import collectionService from '../../services/ApiServices/collection-service';
import nodeService from '../../services/ApiServices/node-service';
import { CollectionItem } from '../../types/collection-types';
import { NodeItem } from '../../types/node.types';
import levelService from '../../services/ApiServices/level-service';
import { Level1Item } from '../../types/levelTypes';
import Select from 'react-select';
import CustomCheckbox from '../common/custom-checkbox';
import { Form, FloatingLabel } from 'react-bootstrap';
import { fuzzyUnorderedSearch } from '../../utils/helpers';
import './styles.css';
import { useAppSelector } from '../../Redux/hooks';

const CreateCollection = () => {
    const { collectionId } = useParams();
    const [collection, setCollection] = useState<CollectionItem | null>();
    const [name, setName] = useState('');
    const [nodes, setNodes] = useState<NodeItem[]>([]);
    const [subjects, setSubjects] = useState<Level1Item[]>([]);
    const [selectedSubject, setSelectedSubject] = useState<number | undefined>(undefined);
    const [nodeIds, setNodeIds] = useState<number[]>([]);
    const [showAllSessions, setShowAllSessions] = useState(false);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const FRONTEND_ROUTES = useAppSelector(state => state.NavBarReducer.frontendRoutes);

    const handleSubmit = () => {
        if (!name.trim()) {
            toast.error('Name is required');
            return;
        }
        const payload = { name, nodeIds };

        if (collectionId) {
            collectionService.updateCollection(+collectionId, { name }).then((res) => {
                if (res.status === 'success') {
                    toast.success(res.message);
                    navigate(FRONTEND_ROUTES?.ADMIN?.COLLECTION + '/edit/' + res.data.collection.id);
                }
            });
        } else {
            collectionService.createNewCollection(payload).then((res) => {
                if (res.status === 'success') {
                    toast.success(res.message);
                    navigate(
                        FRONTEND_ROUTES?.ADMIN?.COLLECTION + '/edit/' + res.data.collection.id,
                        {
                            replace: true,
                        }
                    );
                }
            });
        }
    };

    const fetchCollection = () => {
        if (collectionId) {
            collectionService.getCollection(+collectionId).then((res) => {
                if (res.status === 'success') {
                    const clctn = res.data.collection;
                    setCollection(clctn);
                    if (clctn.nodeCollections.length) {
                        setSelectedSubject(clctn.nodeCollections[0].level1Id);
                        setNodeIds(clctn.nodeCollections.map((i) => i.id));
                    }
                    setName(res.data.collection.name);
                }
            });
        }
    };

    useEffect(() => {
        levelService.getItems(1).then((res) => {
            if (res.status === 'success') {
                setSubjects(res.data.level);
            }
        });
        nodeService.getAll().then((res) => {
            if (res.status === 'success') {
                setNodes(res.data.nodes);
            }
        });
        fetchCollection();
    }, [collectionId]);

    const handleSubjectChange = (e: number | undefined) => {
        if (nodeIds.length > 0) {
            toast.error('You need to remove all the existing nodes before changing the Subject');
            return;
        } else {
            setSelectedSubject(e);
        }
    };

    const addNodeToCollection = (nodeIds: number[]) => {
        if (collection && collection.id) {
            collectionService.addNodesToCollection(collection.id, nodeIds).then((res) => {
                if (res.status === 'success') {
                    toast.success(res.status);
                    setNodeIds((prev) => [...prev, ...nodeIds]);
                } else toast(res.message);
            });
        } else {
            setNodeIds((prev) => [...prev, ...nodeIds]);
        }
    };

    const removeNodeFromCollection = (nodeIds: number[]) => {
        if (collection && collection.id) {
            collectionService.removeNodesFromCollection(collection.id, nodeIds).then((res) => {
                if (res.status === 'success') {
                    toast.success(res.status);
                    setNodeIds((prev) => prev.filter((p) => !nodeIds.includes(p)));
                } else toast(res.message);
            });
        } else {
            setNodeIds((prev) => prev.filter((p) => !nodeIds.includes(p)));
        }
    };

    const handleNodeClick = (e: ChangeEvent<HTMLInputElement>, id: number) => {
        const isChecked = e.target.checked;
        if (isChecked) addNodeToCollection([id]);
        else removeNodeFromCollection([id]);
    };

    const filteredNodes = nodes
        .filter(
            (n) =>
                n.level1Id === selectedSubject && (showAllSessions ? true : n.itemType == 'Session')
        )
        .filter((el) => fuzzyUnorderedSearch(el.internalName, search));

    const selectOptions = subjects
        .map((sub) => ({ label: sub.internalName, value: sub.id } as any))
        .sort((a, b) => a.label.localeCompare(b.label));

    return (
        <div className="container px-4 my-3">
            <h4>{collectionId ? 'Edit ' : 'Create '} Collection</h4>
            <div className="w-50">
                <FloatingLabel controlId="floatingPassword" label="Name">
                    <Form.Control
                        size="sm"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder="Type Collection Name"
                    />
                </FloatingLabel>{' '}
            </div>
            <div className="my-2  w-100 d-flex flex-column gap-3" style={{ zIndex: 99999 }}>
                <Select
                    className="w-50"
                    options={selectOptions}
                    placeholder="Select Subject"
                    isClearable
                    isSearchable
                    value={selectOptions.find((i) => i.value == selectedSubject)}
                    onChange={(e: any) => handleSubjectChange(+e?.value || undefined)}
                />
                <div className="w-50">
                    <CustomCheckbox
                        checked={showAllSessions}
                        onChecked={(_, v) => setShowAllSessions(v)}
                        label="Show All Nodes"
                    />
                </div>
                <div className="row">
                    {selectedSubject && (
                        <div className="d-flex flex-column gap-2 list">
                            <div className="btn-holder bg-white">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type to search node"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>

                            {filteredNodes.map((node) => (
                                <div key={node.id} className={`d-flex gap-4`}>
                                    <input
                                        id={node.id.toString()}
                                        type="checkbox"
                                        checked={nodeIds.includes(node.id)}
                                        onChange={(e) => handleNodeClick(e, node.id)}
                                    />
                                    <label htmlFor={node.id.toString()}>{node.internalName}</label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div>
                    <button className="btn btn-primary btn-sm px-4 py-2" onClick={handleSubmit}>
                        {collectionId ? 'Edit' : 'Create'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateCollection;
