import { FormEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { BACKEND_URLS } from '../../config';
import { axiosClient } from '../../services/axiosClient';
import { AssetType, ZoomSession } from '../../types/types';
import { convertISTtoUTC, convertUTCtoIST } from '../../utils/helpers';
import ESaralLoader from '../Spinner/ESaralLoader';
import collectionService from '../../services/ApiServices/collection-service';
import { CollectionItem } from '../../types/collection-types';
import contentServices from '../../services/content.services';
import { Level2Item } from '../../types/levelTypes';
import levelService from '../../services/ApiServices/level-service';
import Select from 'react-select';
import { sessionType } from '../../utils/constants/constants';

const CreateSessionModel = ({ onSuccess }: { onSuccess: () => void }) => {
    const [zoomUsers, setZoomUsers] = useState<any[]>([]);
    const [zoomUserHostId, setZoomUserHostId] = useState('');

    const [faculties, setFaculties] = useState<any[]>([]);
    const [selectedFaculty, setSelectedFaculty] = useState<number | ''>('');

    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isRecording, setIsRecording] = useState(true);
    const [internalName, setInternalName] = useState('');
    const [selectedChapter, setSelectedChapter] = useState<Level2Item | null>(null);
    const [collections, setCollections] = useState<CollectionItem[]>([]);
    const [collection, setCollection] = useState<CollectionItem | null>(null);
    const [platform, setPlatform] = useState(sessionType[0]);

    const [chapters, setChapters] = useState<Level2Item[]>([]);
    const [hasPDF, setHasPdf] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axiosClient.get(BACKEND_URLS.ZOOM_USERS);
                const users = res.data.data;
                setZoomUsers(users);
                setZoomUserHostId(users[0]?.id);
                // setZoomUser(users[0]);

                const facultyRes = await axiosClient.get(BACKEND_URLS.GET_USERS, {
                    params: { role: 'faculty' },
                });
                if (facultyRes.data.status == 'success') {
                    setFaculties(facultyRes.data.data.users);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        collectionService.getAllCollections().then((res) => {
            if (res.status == 'success') {
                setCollections(res.data.collections);
            }
        });
        levelService.getItems(2).then((res) => {
            if (res.status == 'success') {
                setChapters(res.data.level);
            }
        });
        fetchData();
    }, []);

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        // Check if endTime is greater than startTime
        if (new Date(endDate) <= new Date(startDate)) {
            toast.error('The end time cannot be earlier than the start time 🫡.');
            return false; // Validation failed
        }

        setIsLoading(true);
        if (!selectedChapter) return toast.error('Select a chapter');
        const data = {
            type: platform,
            facultyId: selectedFaculty || undefined,
            internalName: internalName,
            startTime: startDate,
            endTime: endDate,
            recording: isRecording,
            hostId: platform == 'ZOOM' ? zoomUserHostId : undefined,
            hasPDF,
        };
        try {
            const sessionCreated = await axiosClient.post(BACKEND_URLS.CREATE_SESSION, data);
            const sessionCreatedData: ZoomSession = sessionCreated.data.data;
            console.log(sessionCreatedData);
            const content = {
                name: sessionCreatedData.internalName,
                assetType: AssetType.SESSION,
                assetId: sessionCreatedData.id,
                type: 'OTHER',
                level2Id: +selectedChapter.id,
                level3Id: null,
                isFree: false,
                parentAssetType: null,
                parentAssetId: null,
                ...(collection && { collectionId: collection?.id }),
            };
            contentServices.postData('', content).then((res) => {
                if (res.status == 'success') {
                    toast.success(res.message);
                    onSuccess();
                }
            });
        } catch (error: any) {
            setIsLoading(false);
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const filteredChs = chapters.filter(
        (i) =>
            !collection || collection?.nodeCollections.map((e) => e.level1Id).includes(i.level1Id)
    );

    if (isLoading) return <ESaralLoader />;

    return (
        <Form onSubmit={handleSubmit} className="p-2">
            <Form.Group controlId="platformSelect" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Platform</Form.Label>
                <div className="col-sm-9">
                    <Form.Select value={platform} onChange={(e) => setPlatform(e.target.value)}>
                        {sessionType.map((type) => (
                            <option value={type} key={type}>{type}</option>
                        ))}
                    </Form.Select>
                </div>
            </Form.Group>

            <Form.Group controlId="startDateTime" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Session Name</Form.Label>
                <div className="col-sm-9">
                    <Form.Control
                        type="text"
                        value={internalName}
                        onChange={(e) => setInternalName(e.target.value)}
                        placeholder="write internal name here..."
                    />
                </div>
            </Form.Group>

            {platform === 'ZOOM' && (
                <Form.Group controlId="zoomUserSelect" className="mb-3 row align-items-center">
                    <Form.Label className="col-sm-3 col-form-label">Users</Form.Label>
                    <div className="col-sm-9">
                        <Form.Select
                            value={zoomUserHostId}
                            onChange={(e) => {
                                const selectedUserId = e.target.value;
                                setZoomUserHostId(selectedUserId);
                            }}
                        >
                            {zoomUsers.map((user) => (
                                <option key={user.hostId} value={user.hostId}>
                                    {user.name} ({user.userEmail})
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </Form.Group>
            )}
            <Form.Group controlId="sessionCollection" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Select Collection</Form.Label>
                <Select
                    className="col-9"
                    value={{ label: collection?.name, value: collection }}
                    onChange={(e) => {
                        setCollection(e?.value || null);
                        setSelectedChapter(null);
                    }}
                    placeholder="Select Collection"
                    options={collections.map((c) => ({ label: c.name, value: c }))}
                    isSearchable
                    isClearable
                />
            </Form.Group>
            <Form.Group controlId="startDateTime" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Select Chapter</Form.Label>
                <Select
                    value={{
                        value: selectedChapter,
                        label: selectedChapter
                            ? `${selectedChapter?.level1Name}-${selectedChapter?.internalName}`
                            : '',
                    }}
                    isClearable
                    className="col-9"
                    onChange={(e) => setSelectedChapter(e?.value || null)}
                    options={filteredChs.map((i) => ({
                        label: `${i.level1Name}-${i.internalName}`,
                        value: i,
                    }))}
                    isSearchable
                    placeholder="Select Chapter"
                />
            </Form.Group>
            <Form.Group controlId="startDateTime" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">Start Date Time</Form.Label>
                <div className="col-sm-9">
                    <Form.Control
                        type="datetime-local"
                        value={startDate ? convertUTCtoIST(startDate) : ''}
                        onChange={(e) => {
                            const date = convertISTtoUTC(e.target.value);
                            setStartDate(date);
                            if (!endDate || new Date(endDate) < new Date(date)) setEndDate(date);
                        }}
                    />
                </div>
            </Form.Group>

            <Form.Group controlId="endDateTime" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">End Date Time</Form.Label>
                <div className="col-sm-9">
                    <Form.Control
                        type="datetime-local"
                        value={endDate ? convertUTCtoIST(endDate) : ''}
                        onChange={(e) => setEndDate(convertISTtoUTC(e.target.value))}
                    />
                </div>
            </Form.Group>

            <Form.Group controlId="faculty" className="mb-3 row align-items-center">
                <Form.Label className="col-sm-3 col-form-label">faculty</Form.Label>
                <div className="col-sm-9">
                    <Form.Select
                        value={selectedFaculty}
                        onChange={(e) => {
                            setSelectedFaculty(+e.target.value);
                        }}
                    >
                        <option disabled value="">
                            Select faculty
                        </option>
                        {faculties.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.name}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            </Form.Group>

            <Form.Group controlId="saveRecording" className="mb-3 row align-items-center">
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                    <Form.Check
                        type="checkbox"
                        label="Save Recording"
                        onChange={(e) => setIsRecording(e.target.checked)}
                        checked={isRecording}
                    />
                </div>
            </Form.Group>

            <Form.Group controlId="savePDF" className="mb-3 row align-items-center">
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                    <Form.Check
                        type="checkbox"
                        label="Has Pdf"
                        onChange={(e) => setHasPdf(e.target.checked)}
                        checked={hasPDF}
                    />
                </div>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isLoading}>
                {'Add'}
            </Button>
        </Form>
    );
};

export default CreateSessionModel;
