import { NavLink } from "react-router-dom";

const UserHeader = () => {

    return (
        <div className="my-3 mx-2">
            <div className="d-flex gap-2">
                <NavLink to="/student/users/user?id=0" className="btn btn-primary">
                    Create User
                </NavLink>
            </div>
        </div>
    );
};

export default UserHeader;
