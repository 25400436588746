import { useEffect, useState } from 'react';
import { RoleResponse } from '../../types/role.types';
import ESaralLoader from '../../components/Spinner/ESaralLoader';
import roleService from '../../services/ApiServices/role-service';
import DataTable from 'react-data-table-component';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import CreateEditRole from '../../components/users/create-edit-role';
import useGetPermission from '../../hooks/useGetPermission';
import PermissionDenied from '../../components/permission-denied/permission-denied';
import MainBtn from '../../components/btn/main-btn';

const RolesPage = () => {
    const [roles, setRoles] = useState<RoleResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [editId, setEditId] = useState<number | undefined>(undefined);
    const { canCreateRole } = useGetPermission()

    const handleRoleModal = (show: boolean) => setShowRoleModal(show);

    const fetchRoles = () => {
        setLoading(true);
        roleService
            .getAllRoles()
            .then((res) => {
                if (res.status === 'success') {
                    setRoles(res.data.roles);
                } else {
                    toast.error(res.message);
                }
            })
            .catch(() => toast.error('Something went wrong'))
            .finally(() => setLoading(false));
    };

    // const handleDelete = (id: number) => {
    //     if (window.confirm('Are you sure you want to delete this role?')) {
    //         roleService
    //             .deleteRole(id)
    //             .then((res) => {
    //                 if (res.status === 'success') {
    //                     toast.success('Role deleted successfully');
    //                     fetchRoles();
    //                 }
    //             })
    //             .catch(() => toast.error('Failed to delete role'));
    //     }
    // };

    useEffect(() => {
        fetchRoles();
    }, []);

    if (!canCreateRole) {
        return <PermissionDenied message="you don't have access of roles" />
    }

    const columns = [
        {
            name: 'Name',
            selector: (row: RoleResponse) => row.name,
            sortable: true,
        },
        {
            name: 'Slug',
            selector: (row: RoleResponse) => row.slug,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row: RoleResponse) => (
                <div className="d-flex gap-2">
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                            setEditId(row.id);
                            handleRoleModal(true);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ),
        },
    ];

    const handleHide = () => {
        handleRoleModal(false);
        setEditId(undefined);
    };
    const handleSuccess = () => {
        setEditId(undefined);
        handleRoleModal(false);
        fetchRoles();
    };

    if (loading) return <ESaralLoader />;

    return (
        <div className='mx-3'>
            <h3>Roles Management</h3>
            <DataTable
                columns={columns}
                data={roles}
                pagination
                highlightOnHover
                striped
                responsive
                progressPending={loading}
                noDataComponent={<div>No roles available...Trying adding some roles</div>}
                customStyles={{
                    table: {
                        style: {
                            width: '90%',
                            margin: 'auto'
                        }
                    }
                }}
            />
            <Modal show={showRoleModal} onHide={handleHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{editId ? 'Edit' : 'Create'} Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateEditRole id={editId} onSuccess={handleSuccess} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <MainBtn
                message="Create Role"
                onClick={() => handleRoleModal(true)}
            />
        </div>
    );
};

export default RolesPage;
