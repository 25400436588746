import { useEffect, useRef, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import AssignPermissions from '../../components/users/assign-permission';
import AssignRoles from '../../components/users/assign-roles';
import UserHeader from '../../components/users/user-header';
import userService from '../../services/user-service';
import { User, userTypesEnum } from '../../types/user-profille-types';
import PermissionDenied from '../../components/permission-denied/permission-denied';
import useGetPermission from '../../hooks/useGetPermission';
import { useAppSelector } from '../../Redux/hooks';

const channel = new BroadcastChannel('user_crud');

const AllUsers = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [searchUser, setSearchUser] = useState('');
    const [userType, setUserType] = useState<keyof typeof userTypesEnum>('ALL');
    const [userId, setUserId] = useState<number | undefined>(undefined);
    const [modalTitle, setModalTitle] = useState<'Role' | 'Permission' | null>(null);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(1);

    const { canViewUser, canAssignRolePermission, canCreateUser } = useGetPermission();


    const pageRef = useRef<number>(1);
    const limitRef = useRef<number>(50);
    const totalPageRef = useRef<number>(1);
    const navigate = useNavigate();
    const FRONTEND_ROUTES = useAppSelector(state => state.NavBarReducer.frontendRoutes);

    const getAllUsers = () => {
        setLoading(true);
        let query = `limit=${limitRef.current}&page=${pageRef.current}&search=${searchUser}`;

        if (userType === 'STAFF') {
            query += `&isStaff=true`;
        } else if (userType === 'STUDENT') {
            query += `&isStaff=false`;
        }

        userService.getAllUsers(query).then((res) => {
            if (res.status === 'success') {
                setUsers(res.data.users);
                if (res.meta.total && res.meta.limit) {
                    setTotalRows(res.meta.total);
                    totalPageRef.current = Math.ceil(res.meta.total / res.meta.limit);
                }
            }
            setLoading(false);
        });
    };

    const userCRUD = (event: any) => {
        const receivedData = event.data;
        if (receivedData.id === 0) {
            const date = new Date();
            setUsers((users) => [
                {
                    ...receivedData.data,
                    createdOn: date.toISOString(),
                    updatedOn: date.toISOString(),
                },
                ...users,
            ]);
        } else {
            setUsers((users) =>
                users.map((user) => (user.id === receivedData.id ? receivedData.data : user))
            );
        }
    };

    useEffect(() => {
        getAllUsers();
        channel.addEventListener('message', userCRUD);
        return () => {
            channel.removeEventListener('message', userCRUD);
        };
    }, [userType]); // Only trigger on type or search change

    const handlePageChange = (page: number) => {
        pageRef.current = page;
        getAllUsers();
    };

    const handleLimitChange = (limit: number) => {
        limitRef.current = limit;
        pageRef.current = 1; // Reset to the first page
        getAllUsers();
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        pageRef.current = 1; // Reset page when search is triggered
        getAllUsers();
    };

    const userTableColumns: TableColumn<User>[] = [
        {
            name: 'Name',
            selector: (row) => row.name ?? '',
            sortable: true,
            cell: (row) => (
                <div className='text-breaker' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {row.name ?? ''}
                </div>
            ),
        },
        {
            name: 'Username',
            selector: (row) => row.username,
            sortable: true,
            cell: (row) => (
                <div className='text-breaker' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {row.username}
                </div>
            ),
        },
        {
            name: 'Created On',
            selector: (row) => (row?.createdOn ? String(row.createdOn) : ''),
            sortable: true,
            cell: (row) => (
                <div className='text-breaker' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {row?.createdOn ? String(row.createdOn) : ''}
                </div>
            ),
        },
    ]

    if (canAssignRolePermission) {
        userTableColumns.push({
            name: 'Assign',
            cell: (row) => (
                <div className="gap-2">
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                            setUserId(row.id);
                            setModalTitle('Role');
                        }}
                    >
                        Role
                    </Button>
                    <Button
                        className="ms-1"
                        variant="success"
                        size="sm"
                        onClick={() => {
                            setUserId(row.id);
                            setModalTitle('Permission');
                        }}
                    >
                        Permission
                    </Button>
                </div>
            ),
        })
    }

    const columns = userTableColumns;

    if (!canViewUser && canCreateUser) {
        return (
            <div className="mx-3">
                <UserHeader />
            </div>
        )
    }

    if (!canViewUser) {
        return <PermissionDenied message="You don't have permission to access users" />
    }


    return (
        <div className="mx-3">
            <h1>All Users</h1>
            {canCreateUser && <UserHeader />}
            <Form onSubmit={handleSubmit}>
                <Row className="g-3 align-items-center">
                    <Col xs={12} sm={6} md={4} lg={3}>
                        <FloatingLabel controlId="floatingSelect" label="Filter by Type">
                            <Form.Select
                                value={userType}
                                onChange={(e) => {
                                    setUserType(e.target.value as keyof typeof userTypesEnum);
                                }}
                            >
                                <option value={userTypesEnum.ALL}>All</option>
                                <option value={userTypesEnum.STAFF}>Staff</option>
                                <option value={userTypesEnum.STUDENT}>Student</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4}>
                        <FloatingLabel controlId="SearchUserbyNumber" label="Search User">
                            <Form.Control
                                placeholder="Search User"
                                value={searchUser}
                                onChange={(e) => setSearchUser(e.target.value)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} sm="auto">
                        <Button type="submit" className="w-100">
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>

            <DataTable
                columns={columns}
                data={users}
                noDataComponent={<div>No Data Available</div>}
                pagination
                paginationPerPage={limitRef.current}
                paginationRowsPerPageOptions={[50, 100, 500, 1000]}
                onRowClicked={(e) => {
                    navigate(`${FRONTEND_ROUTES?.STUDENT?.USERS}/user?search=${e.username}&id=${e.id}`);
                }}
                paginationServer
                pointerOnHover
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                paginationTotalRows={totalRows}
                progressPending={loading}
            />

            <Modal show={!!modalTitle} onHide={() => setModalTitle(null)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Assign {modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {userId && modalTitle === 'Role' && <AssignRoles id={userId} />}
                    {userId && modalTitle === 'Permission' && <AssignPermissions id={userId} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalTitle(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AllUsers;
